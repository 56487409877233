// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TMN1sgKUyQ1jXedFaot94A\\=\\= {\n  flex: 1;\n}\n\n.IdwrgaZTdDyYJ6x7PDgv3w\\=\\= {\n  flex: 0.05;\n\n  /* background-color: #ccc; */\n}\n\n.aaKIMb-LmaGOAjZXpbMbUA\\=\\= {\n  width: 5%; /* Adjust as needed */\n  margin-right: 2%;\n  /* background-color: rgba(161, 99, 29, 0.9); */\n  /* color: white; */\n  /* height: 100%;  */\n  /* background-color: #ccc; */\n  /* Add margin or padding to create space */\n}\n\n.\\+H5XhcTCceQfEogVmibtCg\\=\\= {\n  flex: 1;\n}\n\n._8SGjUD927uHKFJsVfQE2dw\\=\\= {\n  flex: 1;\n  text-align: right;\n}\n.\\+H5XhcTCceQfEogVmibtCg\\=\\= {\n  flex: 1;\n}\n\n._8SGjUD927uHKFJsVfQE2dw\\=\\= {\n  flex: 1;\n  text-align: right;\n}\n\n/* @media only screen and (max-width: 1100px) {\n  .box {\n    width: 10%;\n  }\n} */\n", "",{"version":3,"sources":["webpack://src/components/subcomponents/Map/ImageAnnotate/ImageAnnotate.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;AACT;;AAEA;EACE,UAAU;;EAEV,4BAA4B;AAC9B;;AAEA;EACE,SAAS,EAAE,qBAAqB;EAChC,gBAAgB;EAChB,8CAA8C;EAC9C,kBAAkB;EAClB,mBAAmB;EACnB,4BAA4B;EAC5B,0CAA0C;AAC5C;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;EACP,iBAAiB;AACnB;AACA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;EACP,iBAAiB;AACnB;;AAEA;;;;GAIG","sourcesContent":[".boxViewer {\n  flex: 1;\n}\n\n.boxButtons {\n  flex: 0.05;\n\n  /* background-color: #ccc; */\n}\n\n.box {\n  width: 5%; /* Adjust as needed */\n  margin-right: 2%;\n  /* background-color: rgba(161, 99, 29, 0.9); */\n  /* color: white; */\n  /* height: 100%;  */\n  /* background-color: #ccc; */\n  /* Add margin or padding to create space */\n}\n\n.leftDiv {\n  flex: 1;\n}\n\n.rightDiv {\n  flex: 1;\n  text-align: right;\n}\n.leftDiv {\n  flex: 1;\n}\n\n.rightDiv {\n  flex: 1;\n  text-align: right;\n}\n\n/* @media only screen and (max-width: 1100px) {\n  .box {\n    width: 10%;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxViewer": "TMN1sgKUyQ1jXedFaot94A==",
	"boxButtons": "IdwrgaZTdDyYJ6x7PDgv3w==",
	"box": "aaKIMb-LmaGOAjZXpbMbUA==",
	"leftDiv": "+H5XhcTCceQfEogVmibtCg==",
	"rightDiv": "_8SGjUD927uHKFJsVfQE2dw=="
};
export default ___CSS_LOADER_EXPORT___;
