import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Icon } from "@iconify/react";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import AccessTime from "@material-ui/icons/AccessTime";
import { Fullscreen } from "cesium";

const MapExpandButtons = (props) => {
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function errors(err) {
    alert("Please change location permission settings in your system");
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  const [show3dBtns, setShow3dBtns] = useState(false);
  // const [transparencyEnabled, setTransparencyEnabled] = useState(false);
  const [buildingEnable, setBuildingEnable] = useState(false);
  const {
    rightDrawer,
    // mapRef,
    viewer,
    showTerrain,
    terrainShowHandler,
    setShowTerrain,
    buildings,
    setBuildings,
    tileset,
    vrCardboard,
    locationIntervalFlag,
    isIOS,
    navigator,
    clearInterval,
    setLocationIntervalFlag,
    success,
    showCompBtn,
    setShowCompBtn,
    device,
  } = props;

  return (
    <div
      id="measureContainer2"
      style={{ right: rightDrawer ? rightDrawer - 95 : rightDrawer }}
    >
      {show3dBtns && (
        <>
          {device === "Desktop" && (
            <Tooltip title={"Compare"} placement="top">
              <IconButton
                style={{
                  backgroundColor: showCompBtn ? "#901da1" : "",
                }}
                onClick={(e) => {
                  setShowCompBtn(!showCompBtn);
                }}
                aria-label="delete"
              >
                <AccessTime />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={"Terrain Mode"} placement="top">
            <IconButton
              style={{
                backgroundColor: showTerrain ? "#901da1" : "",
              }}
              onClick={(e) => {
                terrainShowHandler();
                setShowTerrain(!showTerrain);

                // globe.translucency.enabled = !globe.translucency.enabled;
                // setTransparencyEnabled(!transparencyEnabled);
              }}
            >
              <ThreeDRotationIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Translucency"} placement="top">
            <IconButton
              className={props.transparencyEnabled ? "buttonSelected" : ""}
              // style={{ marginRight: 7 }}
              onClick={(e) => {
                // const viewer = mapRef.current.cesiumElement;
                // const globe = viewer.scene.globe;
                // globe.translucency.enabled = !globe.translucency.enabled;
                props.setTransparencyEnabled(!props.transparencyEnabled);
              }}
            >
              <BlurOnIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"3D Buildings"} placement="top">
            <IconButton
              className={buildingEnable ? "buttonSelected" : ""}
              onClick={(e) => {
                //const viewer = mapRef.current.cesiumElement;

                if (!buildingEnable) {
                  if (!buildings) {
                    viewer.scene.primitives.add(tileset);
                    setBuildings(true);
                  }
                  //setShowTerrain(true);
                  tileset.show = true;
                  setBuildingEnable(true);
                } else {
                  tileset.show = false;
                  setBuildingEnable(false);
                  //setShowTerrain(false);
                }
                //terrainShowHandler();
              }}
            >
              <LocationCityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"VR Mode"} placement="top">
            <IconButton
              onClick={(e) => {
                //  const viewer = mapRef.current.cesiumElement;
                if (
                  document.getElementsByClassName("header-main")[0].style
                    .display !== "none"
                ) {
                  document.getElementsByClassName(
                    "header-main"
                  )[0].style.display = "none";
                  document.getElementById("myDIV").style.display = "none";
                  document.getElementById("measureContainer1").style.display =
                    "none";
                  document.getElementsByClassName(
                    "MuiDrawer-root"
                  )[0].style.display = "none";
                  document.getElementsByClassName(
                    "marginbelow"
                  )[0].style.paddingTop = 0;
                  document.getElementById("toolbarHidden").style.display =
                    "none";
                  document
                    .getElementsByClassName("cesium-viewer")[0]
                    .style.setProperty(
                      "height",
                      "calc(100vh - 0px)",
                      "important"
                    );
                  viewer.scene.useWebVR = true;
                  Fullscreen.requestFullscreen(document.body);
                } else {
                  document.getElementsByClassName(
                    "header-main"
                  )[0].style.display = "";
                  document.getElementById("myDIV").style.display = "";
                  document.getElementById("measureContainer1").style.display =
                    "";
                  document.getElementsByClassName(
                    "MuiDrawer-root"
                  )[0].style.display = "";
                  document.getElementsByClassName(
                    "marginbelow"
                  )[0].style.paddingTop = "";
                  document.getElementById("toolbarHidden").style.display = "";
                  document.getElementsByClassName(
                    "cesium-viewer"
                  )[0].style.height = "";
                  viewer.scene.useWebVR = false;
                  if (document.fullscreenElement) {
                    Fullscreen.exitFullscreen();
                  }
                }
                // setVRenable(!VRenable)
              }}
            >
              {/* <ThreeDRotationIcon />
               */}
              <Icon style={{ fontSize: "18px" }} icon={vrCardboard} />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip title={"Expand"} placement="top">
        <IconButton
          style={{ backgroundColor: show3dBtns ? "#901da1" : "" }}
          // style={{ marginRight: 7 }}
          onClick={(e) => {
            setShow3dBtns(!show3dBtns);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {/* live location will bring back in future commented for now */}
      <Tooltip title={"Locate Me"} placement="top">
        <IconButton
          className={locationIntervalFlag && "blinking"}
          style={{
            background: locationIntervalFlag ? "#901da1e6" : "",
            color: locationIntervalFlag ? "white" : "",
          }}
          onClick={(e) => {
            if (!locationIntervalFlag) {
              if (!isIOS) {
                if (navigator.geolocation) {
                  navigator.permissions
                    .query({ name: "geolocation" })
                    .then(function(result) {
                      if (result.state === "granted") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success);
                      } else if (result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition(
                          success,
                          errors,
                          options
                        );
                      } else if (result.state === "denied") {
                        alert(
                          "Please change location permission settings in your system"
                        );
                        //If denied then you have to show instructions to enable location
                      }
                      result.onchange = function() {
                        console.log(result.state);
                      };
                    });
                } else {
                  // alert("Sorry Not available!");
                }
              } else {
                navigator.geolocation.getCurrentPosition(
                  success,
                  errors,
                  options
                );
              }
            } else {
              clearInterval();
              setLocationIntervalFlag(!locationIntervalFlag);
              //const viewer = mapRef.current.cesiumElement;
              viewer.entities._entities._array.filter(
                (locEnt) => locEnt._name == "MYLOCATION"
              )[0].show = !locationIntervalFlag;
              viewer.entities._entities._array.filter(
                (locEnt) => locEnt._name == "MYLOCATION1"
              )[0].show = !locationIntervalFlag;
            }
          }}
          aria-label="delete"
        >
          <MyLocationIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default MapExpandButtons;
