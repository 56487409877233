import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChromePicker, HuePicker } from "react-color";
import Slider from "@material-ui/core/Slider";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../../../../actions/types";
import EditIcon from "@material-ui/icons/Edit";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioMui from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Switch from "react-switch";
import { Input, Modal, Progress, Radio } from "antd";
import MenuItem from "@material-ui/core/MenuItem";
import IconStyles from "../../../icons-style";
import "./customStyles.module.css";
import UndoIcon from "@material-ui/icons/Undo";
import CloseIcon from "@material-ui/icons/Close";
import secureLocalStorage from "react-secure-storage";
import { hexToRgbA } from "../../../../common/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    fontSize: "12px",
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: 500, // Set the text color for the input field
    },
  },
});

let dateCollectedVar = null;
const InfoPanelComp = (props) => {
  const [infoLayerDes, setInfoLayerDes] = useState(props.infoLayerDes);
  const [onChangeFlag, setOnChangeFlag] = useState(false);
  const [selectedPrimitive, setSelectedPrimitive] = useState(
    props.selectedPrimitive
  );

  // const [changedEntity, setChangedEntity] = useState(null);

  let changedStyleEntity = null;
  let uploadFilesImageryVar = [];
  const classes = useStyles();
  const [heightValue, setHeightValue] = useState(null);

  const onChangeHeightValue = (event) => {
    const inputValue = event.target.value;
    if (inputValue == "" || inputValue == "-" || inputValue == "+") {
      setHeightValue(inputValue);
    } else {
      props.setTileSetHeight(0);
      props.setTileSetHeightDefault(parseInt(inputValue));
      setHeightValue(inputValue);
    }
  };
  const updateStyleStateChange = () => {
    console.log("updateStyleStateChange", uploadFilesImageryVar);

    // console.log("changedStyleEntity", changedStyleEntity);
    // const tempArray = props.uploadFilesImagery;
    // tempArray.push(changedStyleEntity);
    // if (changedStyleEntity) {
    //   props.setUploadFilesImagery(tempArray);
    // }
    if (uploadFilesImageryVar.length > 0) {
      props.setUploadFilesImagery(uploadFilesImageryVar);
    }
  };

  const removeElementById = (id, array) => {
    let removedElement = null;
    const newArray = array.filter((item, index) => {
      if (item?.id === id) {
        removedElement = item;
        return false;
      }
      return true;
    });
    return { newArray, removedElement };
  };

  const updateKmlStyle = (datatosend) => {
    //Code To Refresh
    // const tempArray = props.uploadFilesImagery;
    // console.log("tempArray", tempArray);
    // const filterArray = removeElementById(props.layerPanelSelected, tempArray);
    // console.log("filterArray", filterArray);
    // props.setUploadFilesImagery(filterArray.newArray);
    // if (filterArray.removedElement) {
    //   changedStyleEntity = filterArray.removedElement;
    // }
    uploadFilesImageryVar = props.uploadFilesImagery;

    const objIndex = uploadFilesImageryVar.findIndex(
      (obj) => obj.id === props.layerPanelSelected
    );
    if (objIndex !== -1) {
      uploadFilesImageryVar[objIndex].altitudeMode = datatosend.altitudeMode;
      uploadFilesImageryVar[objIndex]["change"] = true;
    }
    props.setUploadFilesImagery([]);

    console.log("dataToSend", datatosend);

    props
      .updateVectorStyles(datatosend)
      .then((res) => {
        console.log("strokeColorRes", res);
        if (res.data.status == 200) {
          updateStyleStateChange();
        }
      })
      .catch((error) => {
        updateStyleStateChange();
      });
  };

  const handleSave = () => {
    if (props.editEntity) {
      Modal.confirm({
        title: "Are you sure you want to edit this layer?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => {
          props.createLayerHandler(
            document.getElementById("vectorNameVal").value
          );
        },
      });
    } else {
      props.dispatch({ type: LOADING_MESSAGE, isLoading: true });
      let attr = {
        description: infoLayerDes,
        color: "N/A",
        fillColor: props.fillColor,
        strokeColor: props.strokeColor,
        transparency: "N/A",
        strokeTransparency: String(props.strokeTrans),
        fillTransparency: String(props.fillTrans),
        tags: props.infoLayerTags,
        links: props.infoLayerLinks,
        altitudeMode: props.selectedAltitudeModeKml,
      };

      let attrFolder = {
        description: infoLayerDes,
        tags: props.infoLayerTags,
        links: props.infoLayerLinks,
      };

      if (props.infoLayerSelected === "Point Cloud") {
        const attrPointCloud = {
          pointcloudStyle: {
            pointCloudSize: props.pointCloudSize,
            styleMode: props.styleMode,
            styleClass: props.styleClass,
            selectedColorPallet: props.selectedColorPallet,
            heightSliderVal: props.heightSliderVal,
            altitudeMode: props.selectedAltitudeMode,
            tileSetHeightDefault: props.tileSetHeightDefault,
            tileSetHeight: props.tileSetHeight,
          },
        };
        props
          .updatePointCloudLayerAttributes(
            attrPointCloud,
            props.infoLayerSelectedId
          )
          .then((res) => {
            if (res) {
              if ((res.data.status = 200)) {
                props.errorDisplay("success", "Styles updated");

                selectedPrimitive._asset.style = attrPointCloud.pointcloudStyle;
              }

              console.log("sucessStyle", attrPointCloud.pointcloudStyle);
            } else {
              props.errorDisplay("error", "Request Unsuccessful");
            }
          });
      }

      if (props.infoLayerSelectedId) {
        let layerId = props.infoLayerSelectedId;
        if (typeof layerId === "string" && layerId.includes("folder_")) {
          props
            .updateLayerAttributesFolder(
              attrFolder,
              layerId.split("folder_")[1],
              props.infoLayerSelectedFeatureId
            )
            .then((res) => {
              if (res) {
                if ((res.data.status = 200)) {
                  props.errorDisplay(
                    "success",
                    "Folder attributes updated successfully"
                  );
                }
              } else {
                props.errorDisplay("error", "Request Unsuccessful");
              }
            });
        } else {
          props
            .updateLayerAttributes(
              attr,
              layerId,
              props.infoLayerSelectedFeatureId
            )
            .then((res) => {
              if (res) {
                if ((res.data.status = 200)) {
                  props.updateAllLayer(layerId, attr);
                  props.errorDisplay("success", "Layer updated successfully");
                }
              } else {
                props.errorDisplay("error", "Request Unsuccessful");
              }
            });
        }
      }
    }
  };

  useEffect(() => {
    setInfoLayerDes(props.infoLayerDes);
  }, [props.infoLayerDes]);

  useEffect(() => {
    if (props.dateCollectedDrawer.confirm && !props.dateCollectedDrawer.open) {
      props.infoPanelDateCollectedHandler(
        props.infoLayerSelectedName,
        dateCollectedVar
      );
    }
  }, [props.dateCollectedDrawer]);

  useEffect(() => {
    let datatosend = {};
    if (
      props.layerPanelSelected &&
      (props.infoLayerSelected == "Polygon" ||
        props.infoLayerSelected == "LineString" ||
        props.infoLayerSelected == "MultiLineString" ||
        props.infoLayerSelected == "Point")
    ) {
      datatosend = {
        //layer: props.infoLayerSelectedWMSName,
        layerID: props.layerPanelSelected,
        altitudeMode: props.selectedAltitudeModeKml,
        userID: Number(props.getUserId()),
      };

      updateKmlStyle(datatosend);
    }
  }, [props.showTerrain]);

  useEffect(() => {
    if (!props.openInfoDrawer && onChangeFlag) {
      handleSave();
    } else {
      setOnChangeFlag(false);
    }
  }, [props.openInfoDrawer]);

  useEffect(() => {
    if (props.selectedPrimitive != null) {
      setSelectedPrimitive(props.selectedPrimitive);
    }
  }, [props.selectedPrimitive]);

  useEffect(() => {
    setOnChangeFlag(false);
  }, [props.infoLayerSelectedId, props.infoLayerSelectedFeatureId]);

  useEffect(() => {
    // if (heightValue != "" || heightValue != "-") {
    setHeightValue(parseInt(props.tileSetHeightDefault + props.tileSetHeight));
  }, [props.tileSetHeightDefault, props.tileSetHeight]);

  // useEffect(() => {
  //   let refreshItem;
  //   let newfiles = props.uploadFilesImagery.filter((delItem) => {
  //     if (delItem.id === props.layerPanelSelected) {
  //       refreshItem = delItem;
  //       return false;
  //     }
  //     return true;
  //   });
  //   props.setUploadFilesImagery(newfiles);
  //   console.log("refreshItem", refreshItem);
  //   if (refreshItem) {
  //     if (props.selectedAltitudeMode == 2) {
  //       refreshItem.url = refreshItem.url.replace(
  //         "altitudemode:absolute",
  //         props.showTerrain
  //           ? "altitudemode:relativeToGround"
  //           : "altitudemode:clampToGround"
  //       );
  //     } else {
  //       refreshItem.url = refreshItem.url.replace(
  //         props.showTerrain
  //           ? "altitudemode:relativeToGround"
  //           : "altitudemode:clampToGround",
  //         "altitudemode:absolute"
  //       );
  //     }
  //     newfiles.push(refreshItem);
  //     props.setUploadFilesImagery(newfiles);
  //     props.setVectorEditted(!props.vectorEditted);
  //   }
  // }, [props.selectedAltitudeMode, props.showTerrain]);

  return (
    <>
      {props.openInfoDrawer ? (
        <Box
          className={
            props.openInfoDrawer
              ? "Text-right Text-right-visible"
              : "Text-right Text-right-invisible"
          }
        >
          <IconButton
            className="infoClose"
            style={{
              left: "auto",
            }}
            onClick={props.handleInfoDrawerClose}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      ) : null}

      <Drawer
        // style={{ flexShrink: 0, width: 240 }}
        style={{
          zoom: 0.7,
        }}
        variant="persistent"
        anchor="right"
        open={props.openInfoDrawer}
        classes={{
          paper: props.classes.drawerPaper + " " + "mobileInfoPanel",
        }}
      >
        <div className={props.classes.drawerHeader}>
          <ToggleButton
            className="infoBack mobileBack"
            value="check"
            onClick={props.handleInfoDrawerClose}
            // selected={selected}
            // onChange={() => {
            //   setSelected(!selected);
            // }}
          >
            <ChevronRightIcon />
          </ToggleButton>
          {secureLocalStorage.getItem("user") !== "share" ? (
            <>
              {props.editableVector && (
                <Button
                  onClick={() => {
                    let newfiles = props.uploadFilesImagery.map((delItem) => {
                      if (delItem.id === props.editableVector.id) {
                        delItem.show = !delItem.show;
                      }
                      return delItem;
                    });
                    props.setUploadFilesImagery(newfiles);
                    if (!props.editEntity) {
                      document.getElementById("vectorNameVal").value =
                        props.infoLayerSelectedName;
                      if (
                        props.editableVector.entity._entityCollection._entities
                          ._array[0]._polyline
                      ) {
                        props.setLineWidth(
                          Number(
                            props.editableVector.entity._entityCollection
                              ._entities._array[0]._kml.extendedData[
                              "stroke-width"
                            ].value
                          )
                        );
                        let rgb1 = hexToRgbA(
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._kml.extendedData.stroke.value
                        );
                        let tempColor1 = {};
                        tempColor1.r = rgb1[0];
                        tempColor1.g = rgb1[1];
                        tempColor1.b = rgb1[2];
                        tempColor1.setColor =
                          "rgba(" +
                          tempColor1.r +
                          "," +
                          tempColor1.g +
                          "," +
                          tempColor1.b +
                          "," +
                          "1" +
                          ")";
                        tempColor1.hex =
                          props.editableVector.entity._entityCollection._entities._array[0]._kml.extendedData.stroke.value;
                        props.setLineColor(tempColor1);
                        props.editVector(
                          "line",
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._polyline._positions._value,
                          props.editableVector.entity
                        );
                      } else if (
                        props.editableVector.entity._entityCollection._entities
                          ._array[0]._polygon
                      ) {
                        let rgb = hexToRgbA(
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._kml.extendedData.fill.value
                        );
                        let tempColor = {};
                        tempColor.r = rgb[0];
                        tempColor.g = rgb[1];
                        tempColor.b = rgb[2];
                        tempColor.setColor =
                          "rgba(" +
                          tempColor.r +
                          "," +
                          tempColor.g +
                          "," +
                          tempColor.b +
                          "," +
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._kml.extendedData[
                            "fill-opacity"
                          ].value +
                          ")";
                        tempColor.hex =
                          props.editableVector.entity._entityCollection._entities._array[0]._kml.extendedData.fill.value;
                        props.setFillTrans(
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._kml.extendedData[
                            "fill-opacity"
                          ].value
                        );
                        props.setPolygonBackground(tempColor);
                        props.setLineWidth(
                          Number(
                            props.editableVector.entity._entityCollection
                              ._entities._array[0]._kml.extendedData[
                              "stroke-width"
                            ].value
                          )
                        );
                        let rgb1 = hexToRgbA(
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._kml.extendedData.stroke.value
                        );
                        let tempColor1 = {};
                        tempColor1.r = rgb1[0];
                        tempColor1.g = rgb1[1];
                        tempColor1.b = rgb1[2];
                        tempColor1.setColor =
                          "rgba(" +
                          tempColor1.r +
                          "," +
                          tempColor1.g +
                          "," +
                          tempColor1.b +
                          "," +
                          "1" +
                          ")";
                        tempColor1.hex =
                          props.editableVector.entity._entityCollection._entities._array[0]._kml.extendedData.stroke.value;
                        props.setLineColor(tempColor1);
                        props.editVector(
                          "polygon",
                          props.editableVector.entity._entityCollection
                            ._entities._array[0]._polygon._hierarchy._value
                            .positions,
                          props.editableVector.entity
                        );
                      } else {
                        props.editVector(
                          "point",
                          props.editableVector.entity._entityCollection._entities._array.map(
                            (pointItem) => pointItem._position._value
                          ),
                          props.editableVector.entity
                        );
                      }
                    } else {
                      props.editVector(
                        false,
                        false,
                        props.editableVector.entity
                      );
                    }
                    // entity._entityCollection._entities._array[0]
                  }}
                  style={{ margin: "auto" }}
                  color="primary"
                >
                  {!props.editEntity ? "Edit" : "Cancel"}
                </Button>
              )}
              <Button
                onClick={() => {
                  handleSave();
                }}
                style={{ margin: "auto" }}
                color="primary"
              >
                {props.editEntity ? "Save Vector" : "Save"}
              </Button>
            </>
          ) : null}
        </div>
        <Tooltip title={props.infoLayerSelectedName} placement="top">
          <span
            className="infoText"
            style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
          >
            {props.infoLayerSelectedName && "Name: "}
            {props.infoLayerSelectedName.slice(0, 20).toLowerCase()}
            {props.infoLayerSelectedName.length > 20 && "..."}
          </span>
        </Tooltip>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Date Created:{" "}
          {props.dateInfoPanel.dateCreated != null &&
            props.dateInfoPanel.dateCreated.split("T")[0] +
              " " +
              props.dateInfoPanel.dateCreated.split("T")[1].substring(0, 8)}
        </span>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Date Updated:{" "}
          {props.dateInfoPanel.dateUpdated != null &&
            props.dateInfoPanel.dateUpdated.split("T")[0] +
              " " +
              props.dateInfoPanel.dateUpdated.split("T")[1].substring(0, 8)}
        </span>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Date Collected:
          <TextField
            id="date"
            style={{
              marginTop: "-10px",
              marginLeft: "10px",
              fontSize: "12px",
              color: "blue",
            }}
            className={classes.input + " infoText"}
            // label="Select Date",

            type="date"
            value={
              props.dateInfoPanel.dateCollected &&
              props.dateInfoPanel.dateCollected.split("T")[0]
            }
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={(event) => {
              setOnChangeFlag(true);
              dateCollectedVar = event.target.value;
              let check = props.infoLayerSelectedId
                .toString()
                .includes("folder_");
              if (check) {
                props.handleDateCollectedDrawer({
                  ...props.dateCollectedDrawer,
                  open: true,
                });
              } else {
                props.infoPanelDateCollectedHandler(
                  props.infoLayerSelectedName,
                  dateCollectedVar
                );
              }
              // props.infoPanelDateCollectedHandler(
              //   props.infoLayerSelectedName,
              //   dateCollectedVar
              // );
            }}
          />
        </span>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Description
        </span>

        <div style={{ padding: "15px" }}>
          <TextareaAutosize
            value={infoLayerDes}
            onChange={(e) => {
              setOnChangeFlag(true);
              setInfoLayerDes(e.target.value);
            }}
            aria-label="Description"
            minRows={4}
            className="infoTextArea"
            placeholder="Description"
          />
        </div>

        <div style={{ padding: 12 }}>
          <Autocomplete
            multiple
            onChange={(a, b, c) => {
              setOnChangeFlag(true);
              if (b.length < 6) {
                props.setInfoLayerTags(b);
              } else {
                props.errorDisplay("error", "Maximum 5 Tags can be added");
              }
            }}
            id="tags-filled"
            options={[].map((option) => option.title)}
            freeSolo
            value={props.infoLayerTags ? props.infoLayerTags : []}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Tags"
                placeholder="Tags"
              />
            )}
          />
        </div>
        <div className="infoAccordion">
          <Accordion
            hidden={!props.infoLayerSelectedId}
            expanded={props.mediaAccordian}
            onChange={() => {
              props.setMediaAccordian(!props.mediaAccordian);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
              >
                Media
              </span>
            </AccordionSummary>
            <AccordionDetails className="infoAccordion1">
              {secureLocalStorage.getItem("user") !== "share" ? (
                <IconButton
                  disabled={props.infoLayerSelectedMedia.length > 4}
                  onClick={(e) => {
                    e.preventDefault();
                    props.setUploadMediaDrawer(true);
                  }}
                  // selected={selected}
                  // onChange={() => {
                  //   setSelected(!selected);
                  // }}
                >
                  {" "}
                  <AddCircleOutlineIcon style={{ height: 40, width: 40 }} />
                </IconButton>
              ) : null}
              {props.infoLayerSelectedMedia.map((media, index) => {
                return (
                  <div key={`InfoLayer#${index}`} className="mapImageDiv">
                    <img
                      style={{ cursor: "pointer" }}
                      src={media.mediaKey}
                      onClick={() => {
                        props.setImageViewer(media.mediaKey);
                      }}
                      className="mapInfoImage"
                    />
                    <DeleteIcon
                      onClick={() => {
                        props.dispatch({
                          type: LOADING_MESSAGE,
                          isLoading: true,
                        });
                        props
                          .deleteMedia(media.id, media.layerId, media.featureId)
                          .then((res) => {
                            let infolayer = props.infoLayerSelectedMedia;
                            let filteredMedia = infolayer.filter(
                              (item) => item.id != media.id
                            );
                            props.updateAllLayerMedia(media.layerId, media.id);
                            props.setInfoLayerSelectedMedia(filteredMedia);
                            props.dispatch({
                              type: SET_MESSAGE,
                              payload: {
                                message: "Media Deleted",
                                severity: "success",
                                isOpen: true,
                              },
                            });
                            props.updateLocalStorage(
                              res?.data?.storageDetail?.storageCapacity,
                              res?.data?.storageDetail?.usedStorage
                            );
                          });
                      }}
                      className="mapImageButton"
                    />
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>

          <>
            {/* {secureLocalStorage.getItem("user") !== "share" ? ( */}
            <Accordion className="stylesAccordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span
                  style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
                >
                  Altitude Mode
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {props.infoLayerSelected == "Point Cloud" && (
                  <>
                    <div style={{ padding: "15px 20px 15px 20px" }}>
                      <FormControl
                        size="small"
                        style={{ minWidth: "100%", marginBottom: 12 }}
                      >
                        <Tooltip
                          title={"Click Save to remember changes"}
                          placement="top"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-pallet"
                            MenuProps={{
                              classes: { paper: props.classes.menuPaper },
                            }}
                            value={props.selectedAltitudeMode}
                            onChange={(a, b, c) => {
                              setOnChangeFlag(true);
                              props.setHeight3DTileset(a.target.value);
                              //props.setSelectedAltitudeMode(a.target.value);
                            }}
                          >
                            <MenuItem value={"absolute"} key={1}>
                              Absolute
                            </MenuItem>
                            <MenuItem value={"clampToGround"} key={2}>
                              Clamp To Ground
                            </MenuItem>
                            <MenuItem value={"custom"} key={3}>
                              Custom
                            </MenuItem>
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </div>
                  </>
                )}
                {props.infoLayerSelected === "Point Cloud" &&
                  props.selectedAltitudeMode === "clampToGround" && (
                    <>
                      <div
                        // style={{ margin: "0 15px" }}
                        style={{
                          width: "340px",
                          // backgroundColor: "red",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ margin: "12px auto" }}>
                          <Button
                            // variant="contained"
                            style={{
                              textTransform: "initial",
                            }}
                            variant="outlined"
                            color="primary"
                            endIcon={
                              props.rayCastLoader ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null
                            }
                            onClick={() => {
                              props.rayCastCalculation(props.selectedPrimitive);
                            }}
                          >
                            Improve Clamp to Ground
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                {props.infoLayerSelected == "Point Cloud" &&
                  props.selectedAltitudeMode == "custom" && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Height
                      </span>
                      <div
                        // style={{ margin: "0 15px" }}
                        style={{ width: "340px" }}
                      >
                        <div style={{ margin: "12px" }}>
                          <TextField
                            size="small"
                            value={heightValue}
                            fullWidth
                            variant="outlined"
                            placeholder="Height"
                            type="number"
                            onChange={(event, value) => {
                              setOnChangeFlag(true);

                              onChangeHeightValue(event);
                            }}
                          />
                        </div>
                      </div>

                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Adjust 3D Height
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.tileSetHeight}
                          onChange={(event, value) => {
                            setOnChangeFlag(true);
                            props.setTileSetHeight(value);
                          }}
                          min={-50}
                          step={1}
                          max={50}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    </>
                  )}

                {props.infoLayerSelected == "folder" && (
                  <>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Bulk height adjustment for tilesets
                    </span>
                    <div style={{ padding: "15px 20px 15px 20px" }}>
                      <FormControl
                        style={{ minWidth: "100%", marginBottom: 12 }}
                      >
                        <Tooltip
                          title={"Click Save to remember changes"}
                          placement="top"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-pallet"
                            MenuProps={{
                              classes: { paper: props.classes.menuPaper },
                            }}
                            value={props.selectedAltitudeMode}
                            onChange={(a, b, c) => {
                              setOnChangeFlag(true);
                              props.setHeight3DTileset(a.target.value);
                              //props.setSelectedAltitudeMode(a.target.value);
                            }}
                          >
                            <MenuItem value={"absolute"} key={1}>
                              Absolute
                            </MenuItem>
                            <MenuItem value={"clampToGround"} key={2}>
                              Clamp To Ground
                            </MenuItem>
                            <MenuItem value={"custom"} key={3}>
                              Custom
                            </MenuItem>
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </div>
                  </>
                )}

                {props.infoLayerSelected == "folder" &&
                  props.selectedAltitudeMode == "custom" && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Height
                      </span>
                      <div
                        // style={{ margin: "0 15px" }}
                        style={{ width: "340px" }}
                      >
                        <div style={{ margin: "12px" }}>
                          <TextField
                            size="small"
                            value={
                              // props.tileSetHeight === 0
                              //   ? parseInt(props.tileSetHeightDefault)
                              //   : parseInt(
                              //       props.tileSetHeightDefault +
                              //         props.tileSetHeight
                              //     )
                              parseInt(
                                props.tileSetHeightDefault + props.tileSetHeight
                              )
                            }
                            fullWidth
                            variant="outlined"
                            placeholder="Height"
                            type="number"
                            onChange={(event, value) => {
                              setOnChangeFlag(true);
                              const inputValue = event.target.value;

                              console.log("inputValue", inputValue);

                              props.setTileSetHeight(0);

                              // Ensure the input is a valid number before updating state
                              if (
                                inputValue != "" &&
                                props.tilesetheight != 0
                              ) {
                                props.setTileSetHeightDefault(
                                  parseInt(inputValue)
                                );
                              } else if (props.tilesetheight === 0) {
                                props.setTileSetHeightDefault(0);
                              } else {
                                props.setTileSetHeightDefault(null);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Adjust 3D Height
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.tileSetHeight}
                          onChange={(event, value) => {
                            setOnChangeFlag(true);
                            props.setTileSetHeight(value);
                          }}
                          min={-50}
                          step={1}
                          max={50}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    </>
                  )}

                {(props.infoLayerSelected == "Point" ||
                  props.infoLayerSelected == "LineString" ||
                  props.infoLayerSelected == "MultiLineString" ||
                  props.infoLayerSelected == "Polygon") && (
                  <>
                    <div style={{ padding: "15px 20px 15px 20px" }}>
                      <FormControl
                        style={{ minWidth: "100%", marginBottom: 12 }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select-pallet"
                          MenuProps={{
                            classes: { paper: props.classes.menuPaper },
                          }}
                          value={props.selectedAltitudeModeKml}
                          onChange={(a, b, c) => {
                            setOnChangeFlag(true);
                            props.setSelectedAltitudeModeKml(a.target.value);
                            let datatosend = {};
                            if (
                              props.layerPanelSelected &&
                              (props.infoLayerSelected == "Polygon" ||
                                props.infoLayerSelected == "LineString" ||
                                props.infoLayerSelected == "MultiLineString" ||
                                props.infoLayerSelected == "Point")
                            ) {
                              datatosend = {
                                //layer: props.infoLayerSelectedWMSName,
                                layerID: props.layerPanelSelected,
                                altitudeMode: a.target.value,
                                userID: Number(props.getUserId()),
                              };

                              updateKmlStyle(datatosend);
                            }
                          }}
                        >
                          <MenuItem value={"absolute"} key={1}>
                            Absolute
                          </MenuItem>
                          <MenuItem value={"clampToGround"} key={2}>
                            Clamp To Ground
                          </MenuItem>
                          <MenuItem value={"relativeToGround"} key={3}>
                            Relative To Ground
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            {/* ) : null} */}
            {/* {secureLocalStorage.getItem("user") !== "share" ? ( */}
            {!props.editEntity ? (
              <Accordion className="stylesAccordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <span
                    style={{
                      fontSize: "1.06rem",
                      fontWeight: 600,
                      padding: 10,
                    }}
                  >
                    Styles
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  {props.infoLayerSelected == "Polygon" && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Fill Color
                      </span>
                      <div style={{ padding: "15px 0px 15px 65px" }}>
                        <ChromePicker
                          color={props.fillColor}
                          onChangeComplete={(color) => {
                            setOnChangeFlag(true);
                            let datatosend = {};
                            datatosend = {
                              //layer: props.infoLayerSelectedWMSName,
                              layerID: props.layerPanelSelected,
                              featureType: "polygon",
                              fillColor: color.hex,
                              userID: Number(props.getUserId()),
                              altitudeMode: props.selectedAltitudeModeKml,
                            };
                            // props.updateVectorStyles(datatosend).then((res) => {
                            //   if (res.data.message == "style edited successfully") {
                            //     let refreshItem;
                            //     let newfiles = props.uploadFilesImagery.filter(
                            //       (delItem) => {
                            //         if (delItem.id === props.layerPanelSelected) {
                            //           refreshItem = delItem;
                            //           return false;
                            //         }
                            //         return true;
                            //       }
                            //     );
                            //     props.setUploadFilesImagery(newfiles);
                            //     newfiles.push(refreshItem);
                            //     props.setUploadFilesImagery(newfiles);
                            //     props.setVectorEditted(!props.vectorEditted);
                            //   }
                            // });
                            updateKmlStyle(datatosend);
                            props.setFillColor(color.hex);
                          }}
                          disableAlpha={true}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Fill Transparency
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.fillTrans}
                          onChangeCommitted={(event, value) => {
                            setOnChangeFlag(true);
                            let datatosend = {
                              //layer: props.infoLayerSelectedWMSName,
                              layerID: props.layerPanelSelected,
                              featureType: "polygon",
                              fillOpacity: `${value}`,
                              userID: Number(props.getUserId()),
                              altitudeMode: props.selectedAltitudeModeKml,
                            };
                            // props.updateVectorStyles(datatosend).then((res) => {
                            //   if (res.data.message == "style edited successfully") {
                            //     let refreshItem;
                            //     let newfiles = props.uploadFilesImagery.filter(
                            //       (delItem) => {
                            //         if (delItem.id === props.layerPanelSelected) {
                            //           refreshItem = delItem;
                            //           return false;
                            //         }
                            //         return true;
                            //       }
                            //     );
                            //     props.setUploadFilesImagery(newfiles);
                            //     newfiles.push(refreshItem);
                            //     props.setUploadFilesImagery(newfiles);
                            //     props.setVectorEditted(!props.vectorEditted);
                            //   }
                            // });
                            updateKmlStyle(datatosend);
                            props.setFillTrans(value);
                          }}
                          min={0}
                          step={0.1}
                          max={1}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    </>
                  )}

                  {props.infoLayerSelected == "Point Cloud" &&
                    props.showPointCloudStyleChkBx && (
                      // {true && (
                      <>
                        <span
                          style={{
                            fontSize: "1.06rem",
                            fontWeight: 500,
                            padding: 10,
                          }}
                        >
                          Point Cloud Size
                        </span>
                        <div style={{ padding: "10px 25px 10px 25px" }}>
                          <Slider
                            value={props.pointCloudSize}
                            onChangeCommitted={(event, value) => {
                              setOnChangeFlag(true);
                              props.setPointCloudSize(value);
                            }}
                            min={1}
                            step={1}
                            max={20}
                            valueLabelDisplay="auto"
                            aria-labelledby="continuous-slider"
                          />
                        </div>
                        <span
                          style={{
                            fontSize: "1.06rem",
                            fontWeight: 500,
                            padding: 10,
                          }}
                        >
                          Select Classification Mode
                        </span>
                        <div style={{ padding: "10px 18px 10px 18px" }}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={props.styleMode}
                              onChange={(e) => {
                                setOnChangeFlag(true);
                                props.changeClassificationStyleMode(e);
                              }}
                            >
                              <FormControlLabel
                                value="rgb"
                                control={
                                  <RadioMui
                                    size="small"
                                    classes={{
                                      root: props.classes.radio,
                                      checked: props.classes.checked,
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    style={{ fontSize: "70%", color: "black" }}
                                  >
                                    RGB
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="classification"
                                disabled={props.chkPointCloudClassStyle}
                                control={
                                  <RadioMui
                                    size="small"
                                    classes={{
                                      root: props.classes.radio,
                                      checked: props.classes.checked,
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    style={{ fontSize: "70%", color: "black" }}
                                  >
                                    Classification
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="height"
                                control={
                                  <RadioMui
                                    size="small"
                                    classes={{
                                      root: props.classes.radio,
                                      checked: props.classes.checked,
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    style={{ fontSize: "70%", color: "black" }}
                                  >
                                    Height
                                  </Typography>
                                }
                              />
                              {/* <FormControlLabel
      value="disabled"
      disabled
      control={<RadioMui />}
      label="other"
    /> */}
                            </RadioGroup>
                          </FormControl>
                        </div>

                        {props.styleMode === "height" && (
                          <>
                            <span
                              style={{
                                fontSize: "1.06rem",
                                fontWeight: 500,
                                padding: 10,
                              }}
                            >
                              Style By Height
                            </span>
                            <div style={{ padding: "10px 25px 10px 25px" }}>
                              <Slider
                                value={props.heightSliderVal}
                                onChange={(event, value) => {
                                  setOnChangeFlag(true);
                                  //setPointCloudSize(value);
                                  console.log("valueDoubleSlider", value);
                                  if (value[0] < value[1]) {
                                    props.setHeightSliderVal([
                                      Math.floor(value[0]),
                                      Math.floor(value[1]),
                                    ]);
                                  }
                                }}
                                min={props.minzMaxz[0]}
                                // marks
                                step={
                                  (props.minzMaxz[1] - props.minzMaxz[0]) / 1000
                                }
                                max={props.minzMaxz[1]}
                                valueLabelDisplay="auto"
                                // getAriaLabel={() => 'Minimum distance shift'}
                                disableSwap
                              />
                            </div>
                            {/* <span
                      style={{ fontSize: 12, padding: 10 }}
                    >
                    Select Color Pallet
                    </span> */}
                            <div className="stylePointCloudDiv">
                              <div className="styleItemDiv">
                                <div className="styleItem1">
                                  <div className="labelClass1">
                                    Color Pallet:
                                  </div>
                                </div>

                                <div className="styleItem1">
                                  <FormControl
                                    style={{ minWidth: 140, marginBottom: 12 }}
                                  >
                                    <Select
                                      style={{ fontSize: 11 }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select-pallet"
                                      value={props.selectedColorPallet.id}
                                      MenuProps={{
                                        classes: {
                                          paper: props.classes.menuPaper,
                                        },
                                      }}
                                      onChange={(a, b, c) => {
                                        setOnChangeFlag(true);
                                        props.colorPalletChangeHandler(a, b, c);
                                      }}
                                    >
                                      {props.heightColorList.map((k, i) => {
                                        return (
                                          <MenuItem
                                            value={i}
                                            key={k.colorPalletName + i}
                                          >
                                            {k.colorPalletName}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="styleItem1">
                                  <div className="labelClass1">
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor:
                                          props.selectedColorPallet.color1,
                                      }}
                                    ></span>
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor:
                                          props.selectedColorPallet.color2,
                                      }}
                                    ></span>
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor:
                                          props.selectedColorPallet.color3,
                                      }}
                                    ></span>
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor:
                                          props.selectedColorPallet.color4,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {props.styleMode === "classification" && (
                          <>
                            <span
                              style={{
                                fontSize: "1.06rem",
                                fontWeight: 500,
                                padding: 10,
                              }}
                            >
                              Style Classification
                            </span>
                            <div className="stylePointCloudDiv">
                              {props.styleClass.map((k, i) => {
                                return (
                                  <div
                                    className="styleItemDiv"
                                    key={"rowClass" + k.id}
                                    onMouseEnter={() =>
                                      props.setIsShownClassList({
                                        index: i,
                                        show: true,
                                      })
                                    }
                                    onMouseLeave={() =>
                                      props.setIsShownClassList({
                                        index: i,
                                        show: false,
                                      })
                                    }
                                  >
                                    <div className="styleItem">
                                      {" "}
                                      <div className="labelClass">
                                        {k.className}
                                      </div>
                                    </div>
                                    <div className="styleItem">
                                      <label>
                                        <Switch
                                          onChange={() => {
                                            setOnChangeFlag(true);
                                            props.handleChangeSwitch(a, b, c);
                                          }}
                                          checked={k.active}
                                          height={8}
                                          id={k.id}
                                          width={40}
                                          className="react-switch"
                                          onHandleColor={k.color}
                                          offHandleColor={"#901da1"}
                                          handleDiameter={18}
                                        />
                                      </label>
                                    </div>
                                    <div
                                      className={[
                                        "styleItem",
                                        "iconClass",
                                      ].join(" ")}
                                    >
                                      {" "}
                                      <IconButton
                                        onClick={() => {
                                          console.log(
                                            "styleClass",
                                            props.styleClass
                                          );
                                          console.log("k.id", k.id);
                                          props.styleClassEditHandler(k);
                                        }}
                                        aria-label="edit"
                                      >
                                        {props.isShownClassList.index === i &&
                                        props.isShownClassList.show ? (
                                          <EditIcon />
                                        ) : null}
                                      </IconButton>
                                    </div>
                                    <div
                                      className={[
                                        "styleItem",
                                        "iconClass",
                                      ].join(" ")}
                                    >
                                      {" "}
                                      <IconButton
                                        onClick={() => {
                                          props.styleClassDeleteHandler(k);
                                        }}
                                        aria-label="delete"
                                      >
                                        {props.isShownClassList.index === i &&
                                        props.isShownClassList.show ? (
                                          <UndoIcon />
                                        ) : null}
                                      </IconButton>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                        {props.styleClassEdit.active &&
                          props.styleMode === "classification" && (
                            <>
                              <span
                                style={{
                                  fontSize: "1.06rem",
                                  fontWeight: 500,
                                  padding: 10,
                                }}
                              >
                                Pick Classification Color
                              </span>
                              <span>
                                {" "}
                                <IconButton
                                  style={{ float: "right" }}
                                  aria-label="close"
                                  //  className={classes.closeButton}
                                  onClick={props.closeStyleClassHandler}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </span>
                              <div style={{ padding: "15px 0px 15px 65px" }}>
                                <ChromePicker
                                  color={props.styleClassEdit.color}
                                  onChangeComplete={(color) => {
                                    setOnChangeFlag(true);

                                    console.log("color", color.hex);
                                    props.onChangeClassEditColorHandler(
                                      color.hex
                                    );
                                  }}
                                  disableAlpha={true}
                                />
                              </div>
                            </>
                          )}
                      </>
                    )}
                  {(props.infoLayerSelected == "Polygon" ||
                    props.infoLayerSelected == "LineString" ||
                    props.infoLayerSelected == "MultiLineString") && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Stroke Color
                      </span>
                      <div style={{ padding: "15px 0px 15px 65px" }}>
                        <ChromePicker
                          color={props.strokeColor}
                          onChangeComplete={(color) => {
                            setOnChangeFlag(true);
                            let datatosend = {};
                            if (
                              props.infoLayerSelected == "LineString" ||
                              props.infoLayerSelected == "MultiLineString"
                            ) {
                              datatosend = {
                                //layer: props.infoLayerSelectedWMSName,
                                layerID: props.layerPanelSelected,
                                featureType: "line",
                                strokeColor: color.hex,
                                userID: Number(props.getUserId()),
                                altitudeMode: props.selectedAltitudeModeKml,
                                // visibility: "1",
                                // transparency: "1",
                                // strokeTransparency: "1",
                                // fillTransparency: "1",
                                // fillTrans: "1",
                                // fillOpacity: "1",
                              };
                            } else {
                              datatosend = {
                                //layer: props.infoLayerSelectedWMSName,
                                layerID: props.layerPanelSelected,
                                featureType: "polygon",
                                strokeColor: color.hex,
                                userID: Number(props.getUserId()),
                                altitudeMode: props.selectedAltitudeModeKml,
                                visibility: "1",
                              };
                            }
                            // props.updateVectorStyles(datatosend).then((res) => {
                            //   console.log("ChkServer", res);
                            //   if (res.data.message == "style edited successfully") {
                            //     let refreshItem;
                            //     let newfiles = props.uploadFilesImagery.filter(
                            //       (delItem) => {
                            //         if (delItem.id === props.layerPanelSelected) {
                            //           refreshItem = delItem;
                            //           return false;
                            //         }
                            //         return true;
                            //       }
                            //     );
                            //     props.setUploadFilesImagery(newfiles);
                            //     newfiles.push(refreshItem);
                            //     props.setUploadFilesImagery(newfiles);
                            //     props.setVectorEditted(!props.vectorEditted);
                            //   }
                            // });
                            updateKmlStyle(datatosend);
                            props.setStrokeColor(color.hex);
                          }}
                          disableAlpha={true}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Stroke Width
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.strokeTrans}
                          onChangeCommitted={(event, value) => {
                            setOnChangeFlag(true);
                            let datatosend = {};
                            if (
                              props.infoLayerSelected == "LineString" ||
                              props.infoLayerSelected == "MultiLineString"
                            ) {
                              datatosend = {
                                //layer: props.infoLayerSelectedWMSName,
                                layerID: props.layerPanelSelected,
                                featureType: "line",
                                strokeWidth: Number(value),
                                userID: Number(props.getUserId()),
                                altitudeMode: props.selectedAltitudeModeKml,
                              };
                            } else {
                              datatosend = {
                                //layer: props.infoLayerSelectedWMSName,
                                layerID: props.layerPanelSelected,
                                featureType: "polygon",
                                strokeWidth: Number(value),
                                userID: Number(props.getUserId()),
                                altitudeMode: props.selectedAltitudeModeKml,
                              };
                            }
                            // props.updateVectorStyles(datatosend).then((res) => {
                            //   if (res.data.message == "style edited successfully") {
                            //     let refreshItem;
                            //     let newfiles = props.uploadFilesImagery.filter(
                            //       (delItem) => {
                            //         if (delItem.id === props.layerPanelSelected) {
                            //           refreshItem = delItem;
                            //           return false;
                            //         }
                            //         return true;
                            //       }
                            //     );
                            //     props.setUploadFilesImagery(newfiles);
                            //     newfiles.push(refreshItem);
                            //     props.setUploadFilesImagery(newfiles);
                            //     props.setVectorEditted(!props.vectorEditted);
                            //   }
                            // });
                            updateKmlStyle(datatosend);
                            props.setStrokeTrans(value);
                          }}
                          min={1}
                          step={1}
                          max={10}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    </>
                  )}
                  {props.infoLayerSelected == "Point" && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Icons
                      </span>
                      <Radio.Group
                        style={{ padding: 15 }}
                        size="large"
                        // onChange={props.handleSelectedIconValue}
                        onChange={(event) => {
                          setOnChangeFlag(true);
                          let datatosend = {};
                          datatosend = {
                            //layer: props.infoLayerSelectedWMSName,
                            layerID: props.layerPanelSelected,
                            featureType: "point",
                            userID: Number(props.getUserId()),
                            altitudeMode: props.selectedAltitudeModeKml,
                            iconURL:
                              props.ICON_URL + event.target.value + ".png",
                          };
                          console.log("datatosend", datatosend);
                          // props.updateVectorStyles(datatosend).then((res) => {
                          //   console.log("ChkServer", res);
                          //   if (res.data.message == "style edited successfully") {
                          //     let refreshItem;
                          //     let newfiles = props.uploadFilesImagery.filter(
                          //       (delItem) => {
                          //         if (delItem.id === props.layerPanelSelected) {
                          //           refreshItem = delItem;
                          //           return false;
                          //         }
                          //         return true;
                          //       }
                          //     );
                          //     props.setUploadFilesImagery(newfiles);
                          //     newfiles.push(refreshItem);
                          //     props.setUploadFilesImagery(newfiles);
                          //     props.setVectorEditted(!props.vectorEditted);
                          //   }
                          // });
                          updateKmlStyle(datatosend);
                        }}
                      >
                        <IconStyles />
                      </Radio.Group>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* ) : null} */}
            <Accordion hidden>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span
                  style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
                >
                  Extruded Height
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ padding: "10px 25px 10px 25px" }}>
                  <Slider
                    // value={fillTrans}
                    onChangeCommitted={(event, value) => {
                      setOnChangeFlag(true);
                      let changedEntity;
                      let newfiles = props.uploadFiles.filter((entity) => {
                        if (entity.id == props.infoLayerSelectedId) {
                          entity.exth = value;

                          changedEntity = entity;
                        } else {
                          return entity;
                        }
                      });
                      props.setUploadfiles(newfiles);
                      newfiles.push(changedEntity);
                      props.setUploadfiles(newfiles);
                      props.setFillTrans(value);
                    }}
                    min={0}
                    step={50}
                    max={1000}
                    valueLabelDisplay="auto"
                    aria-labelledby="continuous-slider"
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </>
          <Accordion
            expanded={props.attrAccordian}
            onChange={() => {
              props.setAttrAccordian(!props.attrAccordian);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
              >
                Attributes
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className="infoPanelDescription"
                dangerouslySetInnerHTML={{
                  __html: props.infoLayerProperties,
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={props.linkAccordian}
            onChange={() => {
              props.setLinkAccordian(!props.linkAccordian);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
              >
                Links
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: 12, textAlign: "center" }}>
                <Autocomplete
                  multiple
                  onChange={(a, b, c) => {
                    setOnChangeFlag(true);
                    if (b.length < 6) {
                      if (
                        props.validURL(b[b.length - 1]) ||
                        c == "remove-option" ||
                        c == "clear"
                      ) {
                        props.setInfoLayerLinks(b);
                      } else {
                        props.errorDisplay("error", "Please add a valid link");
                      }
                    } else {
                      props.errorDisplay(
                        "error",
                        "Maximum 5 Links can be added"
                      );
                    }
                  }}
                  id="links-filled"
                  options={[].map((option) => option.title)}
                  freeSolo
                  value={props.infoLayerLinks ? props.infoLayerLinks : []}
                  renderTags={(value, getLinkProps) => {
                    return value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getLinkProps({ index })}
                      />
                    ));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Links"
                      placeholder="Links"
                    />
                  )}
                />
                {props.infoLayerLinks.map((item) => {
                  if (item.includes("youtube") || item.includes("youtu.be")) {
                    return (
                      <iframe
                        className="infoVideo"
                        src={`https://www.youtube.com/embed/${props.getYoutubeId(
                          item
                        )}`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    );
                  } else if (item.includes("matterport")) {
                    return (
                      <iframe
                        width="300"
                        height="300"
                        src={
                          item.includes("m=")
                            ? item
                            : `https://my.matterport.com/show/?m=${
                                item.split("/")[item.split("/").length - 1]
                              }`
                        }
                        frameborder="0"
                        allowfullscreen
                        allow="xr-spatial-tracking"
                      ></iframe>
                    );
                  } else {
                    return (
                      <a
                        className="infoLinks"
                        href={item.includes("http") ? item : "http://" + item}
                        target="_blank"
                      >
                        {item}
                      </a>
                    );
                  }
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Drawer>
    </>
  );
};

export default InfoPanelComp;
