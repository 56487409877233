import React, { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@iconify/react";
import pentagonIcon from "@iconify/icons-mdi/pentagon";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SaveIcon from "@material-ui/icons/Save";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import TimelineIcon from "@material-ui/icons/Timeline";
import UndoIcon from "@material-ui/icons/Undo";
// import AccessTime from "@material-ui/icons/AccessTime";
import { Input, Modal } from "antd";
import secureLocalStorage from "react-secure-storage";

const MapTopButtons = (props) => {
  let {
    VRenable,
    rightDrawer,
    initialMeasurePoints,
    initialMeasurePositions,
    setMeasurePositions,
    setMeasurePoints,
    setEditSelected,
    initialPoints,
    initialPositions,
    initialPositionsNew,
    setPoints,
    setPositions,
    setMeasureSelected,
    initMeasure,
    removeMeasure,
    measureSelected,
    editSelected,
    positions,
    points,
    Cartesian3,
    //mapRef,
    viewer,
    setLayerTypeSelected,
    handleCloseLayer,
    errorDisplay,
    setEditSelectedDouble,
    setSideNav,
    setAppState,
    appState,
    setEditEntity,
    editEntity,
    // showCompBtn,
    // setShowCompBtn,
  } = props;
  let vectorName = "";
  const [showVecBtns, setShowVecBtns] = useState(false);

  const layernameRef = React.useRef();
  return (
    <div
      id="measureContainer1"
      hidden={VRenable}
      style={{ right: rightDrawer ? rightDrawer - 95 : rightDrawer }}
    >
      {/* <div>
        <Tooltip title={"Comapre"} placement="top">
          <IconButton
            style={{
              backgroundColor: showCompBtn ? "#901da1" : "",
            }}
            onClick={(e) => {
              setShowCompBtn(!showCompBtn);
            }}
            aria-label="delete"
          >
            <AccessTime />
          </IconButton>
        </Tooltip>
      </div> */}
      <div>
        <Tooltip title={"Measure Tool"} placement="top">
          <IconButton
            style={{
              backgroundColor: measureSelected ? "#901da1" : "",
            }}
            onClick={(e) => {
              initialMeasurePoints = [];
              initialMeasurePositions = [];
              setMeasurePositions([]);
              setMeasurePoints([]);
              setEditSelected(false);
              initialPoints.splice(0, initialPoints.length);
              initialPositions.splice(0, initialPositions.length);
              initialPositionsNew.splice(0, initialPositionsNew.length);
              setPoints([]);
              setPositions([]);
              removeMeasure();
              initMeasure();
              setMeasureSelected(!measureSelected);
            }}
            aria-label="delete"
          >
            <SquareFootIcon />
          </IconButton>
        </Tooltip>
      </div>
      {secureLocalStorage.getItem("user") !== "share" && !editEntity ? (
        <div>
          <Tooltip title={"Edit Vectors"} placement="top">
            <IconButton
              style={{
                backgroundColor: showVecBtns ? "#901da1" : "",
              }}
              onClick={(e) => {
                if (editSelected && showVecBtns) {
                  setEditSelected(false);
                  setAppState({
                    ...appState,
                    // projectId: null,
                    // folderId: null,
                    fileId: null,
                  });
                  initialPoints.splice(0, initialPoints.length);
                  initialPositions.splice(0, initialPositions.length);
                  initialPositionsNew.splice(0, initialPositionsNew.length);
                  setPositions([]);
                  setPoints([]);
                }
                setShowVecBtns(!showVecBtns);
              }}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : null}
      <div>
        {positions.length || points.length ? (
          <Tooltip title={"Undo"} placement="top">
            <IconButton
              className="mobileShow"
              style={{ marginRight: 7 }}
              disabled={!editSelected}
              onClick={() => {
                initialPositions.pop();
                initialPositions.pop();
                initialPositionsNew.pop();
                initialPoints.pop();
                props.popDragPoints();
                setPoints(initialPoints);
                setPositions(
                  initialPositionsNew.length ? initialPositionsNew : []
                );
              }}
            >
              <UndoIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {showVecBtns && !editEntity && (
          <Tooltip title={"Draw Point"} placement="top">
            <IconButton
              onClick={(e) => {
                if (appState.folderId !== null) {
                  setMeasureSelected(false);
                  initialMeasurePoints = [];
                  initialMeasurePositions = [];
                  setMeasurePositions([]);
                  setMeasurePoints([]);
                  setEditSelected(true);
                  // const viewer = mapRef.current.cesiumElement;
                  viewer.scene.selectionIndicator = false;
                  setEditSelectedDouble(true);
                  initialPoints.splice(0, initialPoints.length);
                  initialPositions.splice(0, initialPositions.length);
                  initialPositionsNew.splice(0, initialPositionsNew.length);
                  setPositions([]);
                  setPoints([]);
                  setLayerTypeSelected("point");
                  handleCloseLayer(e);
                  setSideNav(true);
                } else {
                  errorDisplay(
                    "error",
                    "Please select one Folder to add a Layer!"
                  );
                }

                // addLayer(FiberManualRecordIcon, "#1a73e8", "#e8f0fe");
              }}
              aria-label="delete"
            >
              <FiberManualRecordIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div>
        {editSelected ? (
          <Tooltip title={"Delete"} placement="top">
            <IconButton
              className="mobileShow"
              style={{ marginRight: 7 }}
              disabled={!editSelected}
              onClick={() => {
                initialPoints.splice(0, initialPoints.length);
                initialPositions.splice(0, initialPositions.length);
                initialPositionsNew.splice(0, initialPositionsNew.length);
                setPoints([]);
                setPositions([]);
                initialMeasurePoints = [];
                initialMeasurePositions = [];
                setMeasurePositions([]);
                setMeasurePoints([]);
              }}
              // selected={selected}
              // onChange={() => {
              //   setSelected(!selected);
              // }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {showVecBtns && !editEntity && (
          <Tooltip title={"Draw Line"} placement="top">
            <IconButton
              onClick={(e) => {
                if (appState.folderId !== null) {
                  setMeasureSelected(false);
                  initialMeasurePoints = [];
                  initialMeasurePositions = [];
                  setMeasurePositions([]);
                  setMeasurePoints([]);
                  setEditSelected(true);
                  // const viewer = mapRef.current.cesiumElement;
                  viewer.scene.selectionIndicator = false;
                  setEditSelectedDouble(true);
                  initialPoints.splice(0, initialPoints.length);
                  initialPositions.splice(0, initialPositions.length);
                  initialPositionsNew.splice(0, initialPositionsNew.length);
                  setPositions([]);
                  setPoints([]);
                  setLayerTypeSelected("line");
                  handleCloseLayer(e);
                  setSideNav(true);
                } else {
                  errorDisplay(
                    "error",
                    "Please select one Folder to add a Layer!"
                  );
                }
                // addLayer(TimelineIcon, "#e3742f", "#fcefe3");
              }}
              aria-label="delete"
            >
              <TimelineIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div>
        {editSelected ? (
          <Tooltip title={"Close"} placement="top">
            <IconButton
              className="mobileShow"
              style={{ marginRight: 7 }}
              disabled={!editSelected}
              onClick={(e) => {
                setEditSelected(false);
                setAppState({
                  ...appState,
                  // projectId: null,
                  // folderId: null,
                  fileId: null,
                });
                initialPoints.splice(0, initialPoints.length);
                initialPositions.splice(0, initialPositions.length);
                initialPositionsNew.splice(0, initialPositionsNew.length);
                setPositions([]);
                setPoints([]);
                setEditEntity(null);
                if (editEntity) {
                  editEntity.show = true;
                }
              }}
              aria-label="delete"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {showVecBtns && !editEntity && (
          <Tooltip title={"Draw Polygon"} placement="top">
            <IconButton
              onClick={(e) => {
                if (appState.folderId !== null) {
                  setMeasureSelected(false);
                  initialMeasurePoints = [];
                  initialMeasurePositions = [];
                  setMeasurePositions([]);
                  setMeasurePoints([]);
                  setEditSelected(true);
                  // const viewer = mapRef.current.cesiumElement;
                  viewer.scene.selectionIndicator = false;
                  setEditSelectedDouble(true);
                  initialPoints.splice(0, initialPoints.length);
                  initialPositions.splice(0, initialPositions.length);
                  initialPositionsNew.splice(0, initialPositionsNew.length);
                  setPositions([]);
                  setPoints([]);
                  setLayerTypeSelected("polygon");
                  handleCloseLayer(e);
                  setSideNav(true);
                } else {
                  errorDisplay(
                    "error",
                    "Please select one Folder to add a Layer!"
                  );
                }
                // addLayer(ChangeHistoryIcon, "#a250f5", "#f3e8fd");
              }}
              aria-label="delete"
            >
              <Icon icon={pentagonIcon} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {positions.length || points.length ? (
        <Tooltip title={"Undo"} placement="top">
          <IconButton
            className="mobileHide"
            disabled={!editSelected}
            onClick={() => {
              initialPositions.pop();
              initialPositions.pop();
              initialPoints.pop();
              initialPositionsNew.pop();
              props.popDragPoints();
              setPoints(initialPoints);
              setPositions(
                initialPositionsNew.length ? initialPositionsNew : []
              );
            }}
            // selected={selected}
            // onChange={() => {
            //   setSelected(!selected);
            // }}
          >
            <UndoIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
      {editSelected ? (
        <Tooltip title={"Delete"} placement="top">
          <IconButton
            className="mobileHide"
            disabled={!editSelected}
            onClick={() => {
              // initialPoints = [];
              // initialPositions = [];
              initialPoints.splice(0, initialPoints.length);
              initialPositions.splice(0, initialPositions.length);
              initialPositionsNew.splice(0, initialPositionsNew.length);
              setPoints([]);
              setPositions([]);
              initialMeasurePoints = [];
              initialMeasurePositions = [];
              setMeasurePositions([]);
              setMeasurePoints([]);
            }}
            // selected={selected}
            // onChange={() => {
            //   setSelected(!selected);
            // }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {editSelected ? (
        <Tooltip title={"Close"} placement="top">
          <IconButton
            className="mobileHide"
            disabled={!editSelected}
            onClick={(e) => {
              setEditSelected(false);
              setAppState({
                ...appState,
                // projectId: null,
                // folderId: null,
                fileId: null,
              });
              initialPoints.splice(0, initialPoints.length);
              initialPositions.splice(0, initialPositions.length);
              initialPositionsNew.splice(0, initialPositionsNew.length);
              setPositions([]);
              setPoints([]);
              setEditEntity(null);
              if (editEntity) {
                editEntity.show = true;
              }
            }}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
      {positions.length || points.length ? (
        <Tooltip title={"Save"} placement="top">
          <IconButton
            disabled={!positions.length || !points.length}
            className="blink-bg"
            onClick={() => {
              editEntity
                ? Modal.confirm({
                    title: "Are you sure you want to edit this layer?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk: () => {
                      props.createLayerHandler(
                        document.getElementById("vectorNameVal").value
                      );
                    },
                  })
                : document.getElementById("vectorNameVal").value
                ? props.createLayerHandler(
                    document.getElementById("vectorNameVal").value
                  )
                : Modal.confirm({
                    title: "Are you sure you want to create this layer?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",

                    content: (
                      <>
                        <h3>Layer Name*:</h3>
                        <Input
                          // fullWidth
                          // variant="standard"
                          // style={{ fontSize: 15 }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.createLayerHandler(vectorName);
                              Modal.destroyAll();
                            }
                          }}
                          placeHolder="Layer Name"
                          onChange={(e) => {
                            //console.log("vectorName", e.target.value);
                            // setVectorName(e.target.value);
                            vectorName = e.target.value;
                          }}
                          autoFocus
                          ref={layernameRef}
                          // onChange={newLayerNameChange}
                        />
                      </>
                    ),
                    onOk: () => {
                      props.createLayerHandler(vectorName);
                    },
                    // onCancel: () => {
                    //   layernameRef.current.focus();
                    // },
                  });
              setTimeout(() => {
                layernameRef?.current?.focus();
              }, 1000);
            }}
            aria-label="delete"
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MapTopButtons;
