import React from "react";
import "./customStyle.css";
import DraggableComp from "../Draggable/Draggable";
import styles from "./ImageAnnotate.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import { getYoutubeId } from "../../../../common/common";
import LaunchIcon from "@material-ui/icons/Launch";
const HoverIframe = (props) => {
  return (
    <DraggableComp disabled={props.resizeBtn}>
      {/* <DraggableComp disabled={true}> */}
      <div className="iframeWindow">
        <div className="firstDiv">
          <div
            style={{
              color: "white",
              background: "white",
              borderRadius: "10px 10px 0px 0px",
            }}
            className={styles.boxViewer}
          >
            <Tooltip
              title={"Drag"}
              style={{ zIndex: 999999999999999999 }}
              placement="top"
            >
              <div
                style={{
                  height: "4vh",
                  width: "100%",
                  backgroundColor: "#901da1",
                  // backgroundColor: "red",
                  display: "flex",
                  justifyContent: "flex-space-between",
                  alignItems: "center",
                  borderRadius: "10px 10px 0 0",
                  // flexDirection: "column",
                }}
                onClick={(e) => {
                  props.setResizeBtn(!props.resizeBtn);
                }}
                onMouseEnter={() => {
                  props.setResizeBtn(false);
                }}
                onMouseLeave={() => {
                  props.setResizeBtn(true);
                }}
                id="imgViewerBtnCont"
              >
                <div className={styles.leftDiv}>
                  <span
                    style={{
                      margin: "4%",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 600,
                    }}
                  ></span>
                </div>
                <div className={styles.rightDiv}>
                  <Tooltip
                    title={"Open in new tab"}
                    style={{ zIndex: 999999999999999999 }}
                    placement="top"
                  >
                    <IconButton
                      onClick={(e) => {
                        window
                          .open(
                            props.linkIframe.includes("http")
                              ? props.linkIframe
                              : `http://${props.linkIframe}`,
                            "_blank"
                          )
                          .focus();
                      }}
                      aria-label="delete2"
                      style={{
                        height: "27px",
                        width: "27px",
                        color: "white",
                        margin: "2%",
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={"Close Viewer"}
                    style={{ zIndex: 999999999999999999 }}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => {
                        props.onClose();
                      }}
                      aria-label="delete1"
                      style={{
                        height: "27px",
                        width: "27px",
                        color: "white",
                        margin: "2%",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Tooltip>

            {props.linkIframe.includes("youtube") ||
            props.linkIframe.includes("youtu.be") ? (
              <iframe
                className="infoVideo"
                src={`https://www.youtube.com/embed/${getYoutubeId(
                  props.linkIframe
                )}`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : props.linkIframe.includes("matterport") ? (
              <iframe
                src={
                  props.linkIframe.includes("m=")
                    ? props.linkIframe
                    : `https://my.matterport.com/show/?m=${
                        props.linkIframe.split("/")[
                          props.linkIframe.split("/").length - 1
                        ]
                      }`
                }
                frameborder="0"
                allowfullscreen
                allow="xr-spatial-tracking"
              ></iframe>
            ) : (
              <iframe
                src={
                  props.linkIframe.includes("http")
                    ? props.linkIframe
                    : `http://${props.linkIframe}`
                }
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </DraggableComp>
  );
};

export default HoverIframe;
