// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HJaW5Yn8vCmC6Pf\\+7zVR-A\\=\\= {\n  flex: 1;\n}\n\n._2dprkoYWQOX4esbAq18Q1A\\=\\= {\n  flex: 0.05;\n\n  /* background-color: #ccc; */\n}\n\n._5Opr53-Te8zEs1YbgnCbDg\\=\\= {\n  width: 5%; /* Adjust as needed */\n  margin-right: 2%;\n  /* background-color: rgba(161, 99, 29, 0.9); */\n  /* color: white; */\n  /* height: 100%;  */\n  /* background-color: #ccc; */\n  /* Add margin or padding to create space */\n}\n.yWGk-uK45ARs4oQ-fUXU4g\\=\\= {\n  flex: 1;\n}\n\n.Y9zVfgaIsW8wfOyD-ybGOw\\=\\= {\n  flex: 1;\n  text-align: right;\n}\n\n/* @media only screen and (max-width: 1100px) {\n  .box {\n    width: 10%;\n  }\n} */\n", "",{"version":3,"sources":["webpack://src/components/subcomponents/Map/Image360/Image360.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;AACT;;AAEA;EACE,UAAU;;EAEV,4BAA4B;AAC9B;;AAEA;EACE,SAAS,EAAE,qBAAqB;EAChC,gBAAgB;EAChB,8CAA8C;EAC9C,kBAAkB;EAClB,mBAAmB;EACnB,4BAA4B;EAC5B,0CAA0C;AAC5C;AACA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;EACP,iBAAiB;AACnB;;AAEA;;;;GAIG","sourcesContent":[".boxViewer {\n  flex: 1;\n}\n\n.boxButtons {\n  flex: 0.05;\n\n  /* background-color: #ccc; */\n}\n\n.box {\n  width: 5%; /* Adjust as needed */\n  margin-right: 2%;\n  /* background-color: rgba(161, 99, 29, 0.9); */\n  /* color: white; */\n  /* height: 100%;  */\n  /* background-color: #ccc; */\n  /* Add margin or padding to create space */\n}\n.leftDiv {\n  flex: 1;\n}\n\n.rightDiv {\n  flex: 1;\n  text-align: right;\n}\n\n/* @media only screen and (max-width: 1100px) {\n  .box {\n    width: 10%;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxViewer": "HJaW5Yn8vCmC6Pf+7zVR-A==",
	"boxButtons": "_2dprkoYWQOX4esbAq18Q1A==",
	"box": "_5Opr53-Te8zEs1YbgnCbDg==",
	"leftDiv": "yWGk-uK45ARs4oQ-fUXU4g==",
	"rightDiv": "Y9zVfgaIsW8wfOyD-ybGOw=="
};
export default ___CSS_LOADER_EXPORT___;
