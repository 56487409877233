import React from "react";
import ReactImageAnnotate from "react-image-annotate";
import "./customStyle.css";
import DraggableComp from "../Draggable/Draggable";
import styles from "./ImageAnnotate.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

const ImageAnnotate = (props) => {
  return (
    <DraggableComp disabled={props.resizeBtn}>
      {/* <DraggableComp disabled={true}> */}
      <div
        style={{
          position: "fixed",
          height: "50vh",
          width: "40vw",
        }}
      >
        <div
          style={{
            display: "flex",
            maxHeight: "50vh",
            height: "50vh",
          }}
        >
          <div style={{ color: "white" }} className={styles.boxViewer}>
            <Tooltip
              title={"Drag"}
              style={{ zIndex: 999999999999999999 }}
              placement="top"
            >
              <div
                style={{
                  height: "4vh",
                  width: "100%",
                  backgroundColor: "#901da1",
                  // backgroundColor: "red",
                  display: "flex",
                  justifyContent: "flex-space-between",
                  alignItems: "center",
                  borderRadius: "10px 10px 0 0",
                  // flexDirection: "column",
                }}
                onClick={(e) => {
                  props.setResizeBtn(!props.resizeBtn);
                }}
                onMouseEnter={() => {
                  props.setResizeBtn(false);
                }}
                onMouseLeave={() => {
                  props.setResizeBtn(true);
                }}
                id="imgViewerBtnCont"
              >
                <div className={styles.leftDiv}>
                  <span
                    style={{
                      margin: "4%",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 600,
                    }}
                  >
                    {props.images[0].name}
                  </span>
                </div>
                <div className={styles.rightDiv}>
                  <Tooltip
                    title={"Info"}
                    style={{ zIndex: 999999999999999999 }}
                    placement="top"
                  >
                    <IconButton
                      onClick={(e) => {
                        props.sidePanelInfoUpdate(
                          props.images[0].getData,
                          props.images[0].k
                        );
                        props.setRightDrawer(350);
                        document.getElementsByClassName(
                          "cesium-viewer-toolbar"
                        )[0].style.right = "263px";
                        props.handleInfoDrawerOpen();
                        props.setMediaAccordian(false);
                        // props.setImageViewer(false);
                      }}
                      aria-label="delete2"
                      style={{
                        height: "27px",
                        width: "27px",
                        color: "white",
                        margin: "2%",
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={"Close Viewer"}
                    style={{ zIndex: 999999999999999999 }}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => {
                        props.closeImageViewerHandler(props.images[0].id);
                        // props.setImageViewer(false);
                      }}
                      aria-label="delete1"
                      style={{
                        height: "27px",
                        width: "27px",
                        color: "white",
                        margin: "2%",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Tooltip>
            {props.imageViewer && (
              <ReactImageAnnotate
                labelImages
                regionClsList={["Urban", "Vegetation", "Roads"]}
                // regionTagList={["Trees", "Houses", "Car", "Potholes"]}
                // images={[
                //   {
                //     src: coverUrl,
                //     name: "Image 1",
                //     regions: [
                //       {
                //         type: "point",
                //         x: 0.46120218579234973,
                //         y: 0.24872946817366803,
                //         highlighted: false,
                //         editingLabels: false,
                //         color: "#2196f3",
                //         id: "5057595660144112",
                //         cls: "Beta",
                //         tags: ["tag2"],
                //       },
                //       {
                //         type: "box",
                //         x: 0.13114754098360656,
                //         y: 0.04872946817366803,
                //         w: 0.17704918032786884,
                //         h: 0.05245901639344263,
                //         highlighted: false,
                //         editingLabels: false,
                //         color: "#4caf50",
                //         cls: "Charlie",
                //         id: "2870869204663624",
                //         tags: ["tag2"],
                //       },
                //       {
                //         type: "polygon",
                //         points: [
                //           [0.1814207650273224, 0.3241393042392418],
                //           [0.34098360655737703, 0.327417992763832],
                //           [0.3256830601092896, 0.42905733702612703],
                //           [0.17704918032786884, 0.4356147140753074],
                //         ],
                //         open: false,
                //         highlighted: true,
                //         color: "#4caf50",
                //         cls: "Charlie",
                //         id: "5630948953938357",
                //         editingLabels: false,
                //         tags: ["tag1"],
                //       },
                //     ],
                //   },
                // ]}
                //enabledTools={["select"]}
                images={props.images}
                // selectedImage={props.selectedImage}
                onExit={(annotationData) => {
                  console.log("ExitEvent", annotationData);
                  props.saved(annotationData);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </DraggableComp>
  );
};

export default ImageAnnotate;
