import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RateReview from "@material-ui/icons/RateReview";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactSortable } from "react-sortablejs";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import FolderIcon from "@material-ui/icons/Folder";
import InfoIcon from "@material-ui/icons/Info";
import FolderActionMenu from "../Menus/FolderActionMenu";
import {
  getFolderInfo,
  getSingleLayer,
} from "../../../../../services/user.service";
import { Cesium3DTileset } from "cesium";
import { SET_MESSAGE } from "../../../../../actions/types";
import secureLocalStorage from "react-secure-storage";
import { sortFnc } from "../../../../common/common";
const RenderChildrenComp = (props) => {
  // const [projectState, setProjectState] = useState(false);

  if (!props.item) return null;
  if (props.item._kml) {
    return (
      <Accordion
        className={props.classes?.dragItem}
        key={`layer${props.project.id}_${props.item._id}`}
        style={{
          margin: 0,
          // background:
          //   Number(props.layerPanelSelected) == Number(props.item.id)
          //     ? "#901da1"
          //     : "",
        }}
        // onDoubleClick={(event) => {
        //   props.fileAccordianOnDoubleClick(event, props.item);
        // }}
        // onClick={(event) => {
        //   event.stopPropagation();
        //   props.setLayerPanelSelected(props.item.id);
        //   props.setAppState({
        //     ...props.appState,
        //     projectId: props.project.id,
        //     folderId: props.item?.folderId,
        //     fileId: props.item.id,
        //   });
        // }}
      >
        <AccordionSummary
          //               style={{color:Number(layerPanelSelected)==Number(item.id)?'white':''}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            onClick={(event) => {
              //event.stopPropagation();
            }}
            style={{
              paddingLeft: "77px",
              paddingRight: "27px",
            }}
            className="projectName"
          >
            {/* <span className={"handle"}>
          <Tooltip title={"Drag"} placement="top">
            <IconButton aria-label="delete" style={{ cursor: "move" }}>
              <DragHandleIcon style={{ color: Number(layerPanelSelected) == Number(item.id) ? 'white' : '' }} />
            </IconButton>
          </Tooltip>
        </span> */}
            <Checkbox
              icon={<VisibilityOffIcon />}
              checkedIcon={<VisibilityIcon />}
              // style={{
              //   background:
              //     Number(props.layerPanelSelected) == Number(props.item.id)
              //       ? "white"
              //       : "",
              // }}
              onClick={(event) => {
                //event.stopPropagation();
              }}
              // hidden={
              //   props.item?.status === null ||
              //   props.item?.status?.status
              //     ?.toLowerCase()
              //     .includes("processing") ||
              //   props.item?.status?.status?.toLowerCase().includes("pending") ||
              //   props.item?.status?.status
              //     ?.toLowerCase()
              //     .includes("undefined") ||
              //   props.item?.status?.status?.toLowerCase().includes("failed")
              // }
              defaultChecked={props.item._show ? true : false}
              onChange={(event, state) => {
                console.log(props.item);
                // event.stopPropagation();
                props.item.show = state;
                // if (props.item._point) props.item._point.show = state;
                // if (props.item._polygon) props.item._polygon.show = state;
                // if (props.item._polyline) props.item._polyline.show = state;
                // // *** need to be update for performance
                // props
                //   .updateLayerShow(
                //     props.item.displayStatus ? 0 : 1,
                //     props.item?.folderId,
                //     props.item.id
                //   )
                //   .then(() => {
                //     if (
                //       props.item.layerType == 6 ||
                //       props.item.layerType == 5
                //     ) {
                //       console.log("props.item.displayStatus", props.item);
                //       props.item.entity.show = props.item.displayStatus
                //         ? true
                //         : false;
                //       // const viewer = mapRef.current.cesiumElement;
                //       //viewer.scene.primitives.remove(item.entity);
                //       props.setMediaAccordian(!props.mediaAccordian);
                //     }
                //     let newfiles = props.uploadFilesImagery.map((delItem) => {
                //       if (delItem.id === props.item.id) {
                //         delItem.show = !delItem.show;
                //       }
                //       return delItem;
                //     });
                //     props.setUploadFilesImagery(newfiles);
                //   });
                // props.item.displayStatus = state;
              }}
            />
            {/* <IconButton>{props.selectIcon(props.item)}</IconButton> */}
            <Tooltip
              title={
                props.item._name
                  ? props.item._name
                  : props.item._polygon
                  ? "Polygon"
                  : props.item._point
                  ? "Point"
                  : props.item._polyline
                  ? "Line String"
                  : "Point"
              }
              placement="top"
              // onDoubleClick={(event) => {
              //   props.fileAccordianOnDoubleClick(event, props.item);
              // }}
              // onClick={(event) => {
              //   event.stopPropagation();
              //   props.setLayerPanelSelected(props.item.id);
              //   props.setAppState({
              //     ...props.appState,
              //     projectId: props.project.id,
              //     folderId: props.item?.folderId,
              //     fileId: props.item.id,
              //   });
              // }}
            >
              <span
                style={{
                  margin: "auto 0px",
                  fontSize: "0.9em",
                  // color:
                  //   Number(props.layerPanelSelected) == Number(props.item.id)
                  //     ? "white"
                  //     : "",
                }}
              >
                {/* <CapitaliseFirstWord
              name={selectName(item).slice(0, 12).toLowerCase().toString()}
            /> */}
                {props.item._name
                  ? props.item._name?.slice(0, 30).toString()
                  : props.item._polygon
                  ? "Polygon"
                  : props.item._point
                  ? "Point"
                  : props.item._polyline
                  ? "Line String"
                  : "Point"}
                {}
                {props.item._name?.length > 35 && "..."}
              </span>
            </Tooltip>
            {/* <IconButton
          aria-label="delete"
          style={{ float: "right", paddingRight: "9px" }}
          onClick={(event) => {
            let featureId;
            props.setInfoLayerSelected("Mosaic");
            featureId = props.item.id;

            if (featureId) {
              props.setInfoLayerSelectedId(featureId);
              props.setInfoLayerSelectedFeatureId(null);
              props.getFeatureMediaFunction(featureId, null);
            } else {
              props.setInfoLayerSelectedId(null);
              props.setInfoLayerSelectedFeatureId(null);
              props.setInfoLayerSelectedMedia([]);
            }
            props.setInfoLayerSelectedWMSName("");
            props.setInfoLayerSelectedName(props.item.name);
            props.setInfoLayerProperties("");
            props.setDateCreated(
              props.item.createdAt.split("T")[0] +
                " " +
                props.item.createdAt.split("T")[1].substring(0, 8)
            );
            props.setRightDrawer(350);
            document.getElementsByClassName(
              "cesium-viewer-toolbar"
            )[0].style.right = "355px";
            props.handleInfoDrawerOpen();
            props.setMediaAccordian(false);
            props.fileAccordianOnDoubleClick(null, props.item);
            //props.infoIconHandler(props.item.entity);
            //Getting Primitives Info

            // const viewer = props.mapRef;
            // console.log(
            //   "featureIdPrimitives",
            //   viewer.scene.primitives,
            //   "viewer",
            //   viewer
            // );
            // const primitivesArray = viewer.scene.primitives._primitives;
            // const primitives3dTileset = [];
            // primitivesArray.forEach((obj) => {
            //   if (obj instanceof Cesium3DTileset) {
            //     primitives3dTileset.push(obj);
            //   }
            // });
            // console.log(
            //   "primitives3dTileset",
            //   primitives3dTileset,
            //   "featureId",
            //   featureId
            // );
          }}
        >
          <InfoIcon />
        </IconButton> */}
            {/* {props.project.permission && (
          <IconButton
            aria-label="delete"
            style={{ position: "absolute", right: "16%" }}
            onClick={(event) => {
              try {
                event.stopPropagation();
                props.actionState.project = props.project;
                props.actionState.item = props.item;
                //   if (event.clientY > 480) {
                //     props.actionState.anchorPosition = {
                //       left: event.clientX - 5,
                //       top: event.clientY - 150,
                //     };
                //   } else {
                //     props.actionState.anchorPosition = {
                //       left:
                //         device === "Desktop"
                //           ? event.clientX - 5
                //           : event.clientX - 170,
                //       top: event.clientY - 20,
                //     };
                //   }
                props.setInfoLayerSelectedId(props.item.id);
                props.setInfoLayerSelectedFeatureId(null);
                props.setAnchorEl(event.currentTarget);
                props.setAppState({
                  ...props.appState,
                  projectId: props.project.id,
                  folderId: props.item?.folderId,
                  fileId: props.item.id,
                });
                // setDisplayItems({ ...displayItems, openActionDialog: true });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            <MoreVertIcon
              style={{
                color:
                  Number(props.layerPanelSelected) == Number(props.item.id)
                    ? "white"
                    : "",
              }}
            />
          </IconButton>
        )} */}
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
  if (props.item.belongsTo) {
    return (
      <Accordion
        expanded={props.openAccordians1.includes(props.item.id)}
        // expanded={projectState}
        className={props.classes?.dragItem}
        key={`Project#${props.project.id}}`}
        id={JSON.stringify({
          action: "project-order",
          projectId: props.project.id,
          from: props.item.displayOrder,
        })}
        style={{ margin: "0 5px 0 5px" }}
        onClick={(event) => {
          // event.stopPropagation();
          console.log("props Project", props);
          props.handleClickCheck(
            props.item,
            props.project,
            props.device,
            event
          );
          //setProjectState(!projectState);
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={(event) => {
                event.stopPropagation();
                props.projectAccordianOnClick(props.item, props.project);
                //setProjectState(!projectState);
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            backgroundColor:
              Number(props.appState.projectId) === Number(props.item.id)
                ? "#bf89c8"
                : "",
          }}
        >
          <Typography
            style={{ width: "fit-content" }}
            onClick={(event) => {
              //event.stopPropagation();
            }}
            className="projectName"
          ></Typography>
          <Typography
            style={{ width: "fit-content", alignSelf: "center" }}
            onClick={(event) => {
              // event.stopPropagation();
            }}
            className="projectName"
          >
            <Tooltip title={props.project.name} placement="top">
              <span
                style={{
                  margin: "auto 0px",
                  width: window.matchMedia("(max-width: 767px)").matches
                    ? 90
                    : 190,
                  display: "inline-block",
                  overflow: "hidden",
                  wordBreak: "break-all",
                  verticalAlign: "middle",
                  color:
                    Number(props.layerPanelSelected) == Number(props.item.id)
                      ? "white"
                      : "black",
                }}
              >
                {props.project.name.slice(0, 20)}
                {props.project.name.length > 20 && "..."}
              </span>
            </Tooltip>
          </Typography>
          <Typography
            style={{ width: "fit-content", flexGrow: 1 }}
            onClick={(event) => {
              //event.stopPropagation();
            }}
            className="projectName"
          >
            <Tooltip title={"Options"} placement="top">
              <IconButton
                onClick={(event) => {
                  try {
                    event.stopPropagation();
                    props.actionStateProj.project = props.project;
                    props.actionStateProj.item = props.item;
                    // if (event.clientY > 480) {
                    //   props.actionStateProj.anchorPosition = {
                    //     left: event.clientX - 5,
                    //     top: event.clientY - 150,
                    //   };
                    // } else {
                    //   // props.actionStateProj.anchorPosition = {
                    //   //   left:
                    //   //     device === "Desktop"
                    //   //       ? event.clientX - 5
                    //   //       : event.clientX - 170,
                    //   //   top: event.clientY - 20,
                    //   // };
                    // }

                    props.setAnchorElProj(event.currentTarget);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                aria-label="delete"
                style={{ float: "right" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Share"} placement="top">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  if (
                    secureLocalStorage.getItem("organizationName") &&
                    secureLocalStorage.getItem("organizationName") != "null" &&
                    secureLocalStorage.getItem("organizationName") !=
                      "undefined"
                  ) {
                  } else {
                    dispatch({
                      type: SET_MESSAGE,
                      payload: {
                        message: "Please set up your team first!",
                        severity: "error",
                        isOpen: true,
                      },
                    });
                    let element = document.getElementById("createHighlight");
                    element.classList.add("blink-Button");
                    setTimeout(() => {
                      element.classList.remove("blink-Button");
                    }, 5000);
                    return;
                  }
                  props.setAppState({
                    ...props.appState,
                    projectId: props.project.id,
                    folderId: null,
                    fileId: null,
                  });
                  props.setDisplayItems({
                    ...props.displayItems,
                    openSharingDialog: true,
                    name: props.project.name,
                    id: props.project.id,
                  });
                }}
                aria-label="delete"
                style={{ float: "right" }}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
            {/* <Menu
              id="card-actions-menu"
              anchorEl={props.anchorElProj}
              keepMounted
              open={
                Boolean(props.anchorElProj) &&
                props.actionStateProj.item === props.item
              }
              style={props.actionStateProj.anchorPosition}
              onClose={() => {
                props.setAnchorElProj(null);
              }}
            >
              {props.project.permission?.delete && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    Modal.confirm({
                      title: "Are you sure you want to delete this Project?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "Yes",
                      okType: "danger",
                      cancelText: "No",
                      onOk: () => {
                        props.deleteProjectFunction(
                          props.actionStateProj.project.id
                        );
                      },
                    });
                    props.setAnchorElProj(null);
                  }}
                >
                  <DeleteIcon style={props.iconStyle} />
                  Delete
                </MenuItem>
              )}
              {props.project.permission?.edit && (
                <>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      props.setAppState({
                        ...props.appState,
                        projectId: props.actionStateProj.project.id,
                        folderId: null,
                        fileId: null,
                      });
                      props.setDisplayItems({
                        ...props.displayItems,
                        name: props.actionStateProj.project.name,
                        editType: "project",
                        openEditDialog: true,
                      });
                      props.setAnchorElProj(null);
                    }}
                  >
                    <EditIcon style={props.iconStyle} />
                    Rename
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      props.setAppState({
                        ...props.appState,
                        projectId: props.actionStateProj.project.id,
                        folderId: null,
                        fileId: null,
                      });
                      props.setDisplayItems({
                        ...props.displayItems,
                        editType: "project",
                        openFolderDialog: true,
                      });
                      props.setAnchorElProj(null);
                    }}
                  >
                    <CreateNewFolderIcon style={props.iconStyle} />
                    Add Folder
                  </MenuItem>
                </>
              )}
              {!props.project.permission?.edit &&
                !props.project.permission?.delete && (
                  <>
                    <MenuItem
                      disabled
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      No action allowed
                    </MenuItem>
                  </>
                )}
            </Menu> */}
            <FolderActionMenu
              setFilePath={props.setFilePath}
              setSection={props.setSection}
              setCurrentSection={(curr) => {
                props.setCurrentSection(curr);
              }}
              anchorElProj={props.anchorElProj}
              actionStateProj={props.actionStateProj}
              item={props.item}
              setAnchorElProj={props.setAnchorElProj}
              project={props.project}
              deleteProjectFunction={props.deleteProjectFunction}
              setAppState={props.setAppState}
              setDisplayItems={props.setDisplayItems}
              iconStyle={props.iconStyle}
              appState={props.appState}
              displayItems={props.displayItems}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            // backgroundColor:"red",
            marginLeft: "-21px",
            marginRight: "0px",
            paddingBottom: 0,
          }}
        >
          {props.item.children && (
            <ReactSortable
              id={`projectId:${props.project.id}`}
              handle={".handle"}
              list={props.item.children}
              setList={(currentList, sortable, store) => {
                if (
                  store.dragging &&
                  store.dragging.props &&
                  JSON.stringify(store.dragging.props.list) !==
                    JSON.stringify(currentList)
                ) {
                  setTimeout(() => {
                    setAppState((sourceList) => {
                      try {
                        const tempList = [...sourceList.projects];
                        const _blockIndex = [...props.blockIndex];
                        const lastIndex = _blockIndex.pop();
                        const lastArr = _blockIndex.reduce(
                          (arr, i) => arr[i]["children"],
                          tempList
                        );
                        lastArr[lastIndex]["children"] = currentList;
                        return { ...props.appState, projects: tempList };
                      } catch (e) {
                        console.log("Error", e);

                        return {
                          ...props.appState,
                          projects: [...sourceList.projects],
                        };
                      }
                    });
                  }, 0);
                }
              }}
              {...props.sortableOptions}
            >
              {sortFnc(props.item.children, props.sortType).map(
                (childBlock, index) => {
                  return (
                    <RenderChildrenComp
                      sortType={props.sortType}
                      addOrVisibility={props.addOrVisibility}
                      updateMapFeatureShow={props.updateMapFeatureShow}
                      setInfoLayerTags={props.setInfoLayerTags}
                      setInfoLayerDes={props.setInfoLayerDes}
                      setSection={props.setSection}
                      setCurrentSection={(curr) => {
                        props.setCurrentSection(curr);
                      }}
                      key={childBlock.id}
                      project={props.project}
                      item={childBlock}
                      blockIndex={[...props.blockIndex, index]}
                      openAccordians1={props.openAccordians1}
                      classes={props.classes}
                      handleClickCheck={props.handleClickCheck}
                      device={props.device}
                      projectAccordianOnClick={props.projectAccordianOnClick}
                      appState={props.appState}
                      actionStateProj={props.actionStateProj}
                      setAnchorElProj={props.setAnchorElProj}
                      anchorElProj={props.anchorElProj}
                      deleteProjectFunction={props.deleteProjectFunction}
                      setFilePath={props.setFilePath}
                      setDisplayItems={props.setDisplayItems}
                      displayItems={props.displayItems}
                      setAppState={props.setAppState}
                      iconStyle={props.iconStyle}
                      sortableOptions={props.sortableOptions}
                      allFolders={props.allFolders}
                      openAccordians2={props.openAccordians2}
                      handleClickCheckFolder={props.handleClickCheckFolder}
                      folderAccordianOnClick={props.folderAccordianOnClick}
                      layerPanelSelected={props.layerPanelSelected}
                      updateFolderShow={props.updateFolderShow}
                      //mapRef={props.mapRef}
                      viewer={props.viewer}
                      setMediaAccordian={props.setMediaAccordian}
                      uploadFilesImagery={props.uploadFilesImagery}
                      setUploadFilesImagery={props.setUploadFilesImagery}
                      actionState={props.actionState}
                      setAnchorEl={props.setAnchorEl}
                      fileAccordianOnDoubleClick={
                        props.fileAccordianOnDoubleClick
                      }
                      setLayerPanelSelected={props.setLayerPanelSelected}
                      updateLayerShow={props.updateLayerShow}
                      selectIcon={props.selectIcon}
                      selectName={props.selectName}
                      setDateCreated={(date) => {
                        props.setDateCreated(date);
                      }}
                      setDateInfoPanel={props.setDateInfoPanel}
                      setInfoLayerSelected={props.setInfoLayerSelected}
                      setInfoLayerSelectedId={props.setInfoLayerSelectedId}
                      setInfoLayerSelectedFeatureId={
                        props.setInfoLayerSelectedFeatureId
                      }
                      getFeatureMediaFunction={props.getFeatureMediaFunction}
                      setInfoLayerSelectedMedia={
                        props.setInfoLayerSelectedMedia
                      }
                      setInfoLayerSelectedWMSName={
                        props.setInfoLayerSelectedWMSName
                      }
                      setInfoLayerSelectedName={props.setInfoLayerSelectedName}
                      setInfoLayerProperties={props.setInfoLayerProperties}
                      setRightDrawer={props.setRightDrawer}
                      // infoIconHandler={props.infoIconHandler}
                      handleInfoDrawerOpen={props.handleInfoDrawerOpen}
                    />
                  );
                }
              )}
            </ReactSortable>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }

  if (
    props.item.isFolder &&
    !props.allFolders?.filter((itemFolder) => itemFolder.id == props.item.id)
      .length
  ) {
    props.allFolders?.push({ ...props.item, projectName: props.project.name });
  }
  return props.item.isFolder
    ? props.openAccordians1.includes(props.project.id) && (
        <Accordion
          expanded={
            props.openAccordians2?.includes(props.item.id)
            // || item.children.filter((childItem)=>Number(layerPanelSelected) == Number(childItem.id)).length
          }
          className={props.classes?.dragItem}
          key={`folder${props.project.id}_${props.item.id}`}
          id={JSON.stringify({
            action: "folder-order",
            projectId: props.project.id,
            parentId: props.item.parentId,
            folderId: props.item.id,
            from: props.item.displayOrder,
          })}
          style={{ margin: "0 5px 0 5px" }}
          onClick={(event) => {
            event.stopPropagation();
            console.log("props Folder", props);
            props.handleClickCheckFolder(
              props.item,
              props.project,
              props.device,
              event
            );
          }}
        >
          <AccordionSummary
            style={{
              // backgroundColor: item.children.filter((childItem)=>Number(layerPanelSelected) == Number(childItem.id)).length ? '#cc72da' : ''
              backgroundColor:
                Number(props.appState?.folderId) == Number(props.item.id)
                  ? "#cc72da"
                  : "",
              paddingRight: "13px",
            }}
            expandIcon={
              <ExpandMoreIcon
                onClick={(event) => {
                  event.stopPropagation();
                  props.folderAccordianOnClick(props.item, props.project);
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                width: "fit-content",
                paddingLeft: "25px",
                // paddingRight: "22px",
              }}
              onClick={(event) => {
                // event.stopPropagation();
              }}
              className="projectName"
            >
              <Checkbox
                icon={<VisibilityOffIcon />}
                checkedIcon={<VisibilityIcon />}
                style={{
                  //   paddingLeft: "27px",
                  background:
                    Number(props.layerPanelSelected) == Number(props.item.id)
                      ? "white"
                      : "",
                }}
                onClick={(event) => {
                  //event.stopPropagation();
                }}
                checked={props.item.displayStatus ? true : false}
                onChange={(event, state) => {
                  event.stopPropagation();
                  // *** need to be update for performance
                  props.updateFolderShow(
                    props.item.displayStatus ? 0 : 1,
                    props.item.id
                  );
                  props.item.children.forEach((layer) => {
                    const showItem = state ? true : false;
                    if (!layer.isFolder) {
                      if (layer.layerType == 6 && layer.entity) {
                        props.updateMapFeatureShow(layer.id, showItem);

                        props.setMediaAccordian(!props.mediaAccordian);
                      } else if (
                        (!layer.hasOwnProperty("entity") &&
                          layer.layerType == 6) ||
                        layer.layerType == 2
                      ) {
                        props.setMediaAccordian(!props.mediaAccordian);
                        layer.displayStatus = showItem;
                        props.addOrVisibility(layer.id, showItem, layer);
                      } else if (layer.layerType == 5 && layer.entity) {
                        layer.entity.show = state ? true : false;
                        props.setMediaAccordian(!props.mediaAccordian);
                        let newfiles = props.uploadFilesImagery.map(
                          (delItem) => {
                            if (delItem.id === layer.id) {
                              delItem.show = state ? true : false;
                            }
                            return delItem;
                          }
                        );
                        props.setUploadFilesImagery(newfiles);
                      } else {
                        let newfiles = props.uploadFilesImagery.map(
                          (delItem) => {
                            if (delItem.id === layer.id) {
                              delItem.show = state ? true : false;
                            }
                            return delItem;
                          }
                        );
                        props.setUploadFilesImagery(newfiles);
                      }
                      layer.displayStatus = state;
                    }
                  });
                  props.item.displayStatus = state;
                }}
              />
              <IconButton
                style={props.level ? { paddingLeft: props.level * 9 } : {}}
              >
                <FolderIcon
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
              </IconButton>
            </Typography>
            <Typography
              style={{ width: "fit-content", alignSelf: "center" }}
              onClick={(event) => {
                // event.stopPropagation();
              }}
              className="projectName"
            >
              <Tooltip title={props.item.name} placement="top">
                <span
                  style={{
                    margin: "auto 0px",
                    width: window.matchMedia("(max-width: 767px)").matches
                      ? 90
                      : 190,
                    display: "inline-block",
                    overflow: "hidden",
                    wordBreak: "break-all",
                    verticalAlign: "middle",
                    color:
                      Number(props.layerPanelSelected) == Number(props.item.id)
                        ? "white"
                        : "",
                  }}
                >
                  {props.item.name.slice(0, 30)}
                  {props.item.name.length > 30 && "..."}
                </span>
              </Tooltip>
            </Typography>
            <Typography
              style={{ width: "fit-content", flexGrow: 1 }}
              onClick={(event) => {
                //event.stopPropagation();
              }}
              className="projectName"
            >
              <IconButton
                aria-label="delete"
                style={{ float: "right" }}
                onClick={(event) => {
                  let featureId;
                  console.log("folderProperties", props.item);
                  //props.setInfoLayerSelected("Mosaic");
                  props.setDateCreated(
                    props.item.createdAt.split("T")[0] +
                      " " +
                      props.item.createdAt.split("T")[1].substring(0, 8)
                  );

                  props.setDateInfoPanel({
                    dateCreated: props.item?.createdAt,
                    dateUpdated: props.item?.updatedAt,
                    dateCollected:
                      props.item?.dateCollected != null
                        ? props.item?.dateCollected
                        : "",
                  });
                  featureId = "folder_" + props.item.id;
                  if (featureId) {
                    props.setInfoLayerSelectedId(featureId);
                    props.setInfoLayerSelectedFeatureId(null);
                    props.setInfoLayerTags([]);
                    props.setInfoLayerDes("");
                    //props.getFeatureMediaFunction(featureId, null);
                  } else {
                    props.setInfoLayerSelectedId(null);
                    props.setInfoLayerSelectedFeatureId(null);
                    props.setInfoLayerSelectedMedia([]);
                  }
                  props.setInfoLayerSelectedWMSName("");
                  props.setInfoLayerSelectedName(props.item.name);
                  getFolderInfo(props.item.id).then((res) => {
                    var tableData =
                      '<table style="display: table;width: 100%;overflow: auto;"><tr><td>Name</td><td>Value</td></tr>';
                    tableData += "<tr>";
                    tableData += "<td>" + "Total Items" + "</td>";
                    tableData +=
                      "<td>" + res.data.folderDetails.totalFiles + "</td></tr>";
                    tableData += "<tr>";
                    tableData += "<td>" + "Total Size" + "</td>";
                    tableData +=
                      "<td>" +
                      res.data.folderDetails.totalSize.toFixed(2) +
                      " MB" +
                      "</td></tr>";
                    tableData += "<tr>";
                    tableData += "<td>" + "Belongs to" + "</td>";
                    tableData +=
                      "<td>" + res.data.folderDetails.belongsTo + "</td></tr>";
                    tableData += "</table>";
                    props.setInfoLayerProperties(tableData);
                  });
                  props.setRightDrawer(350);
                  document.getElementsByClassName(
                    "cesium-viewer-toolbar"
                  )[0].style.right = "263px";
                  props.handleInfoDrawerOpen();
                  props.setMediaAccordian(false);
                  props.setInfoLayerSelected("folder");
                }}
              >
                <InfoIcon />
              </IconButton>
              {props.project.permission && (
                <IconButton
                  style={{
                    position: "absolute",
                    right: window.matchMedia("(max-width: 767px)").matches
                      ? "22%"
                      : "16%",
                  }}
                  onClick={(event) => {
                    try {
                      event.stopPropagation();
                      // const element = document.getElementById(`display_order${project.id}_${item.id}`);
                      // actionState.anchorPosition = element.getBoundingClientRect();
                      props.actionState.project = props.project;
                      props.actionState.item = props.item;

                      //   if (event.clientY > 480) {
                      //     props.actionState.anchorPosition = {
                      //       left: event.clientX - 5,
                      //       top: event.clientY - 150,
                      //     };
                      //   } else {
                      //     props.actionState.anchorPosition = {
                      //       left:
                      //         device === "Desktop"
                      //           ? event.clientX - 5
                      //           : event.clientX - 170,
                      //       top: event.clientY - 20,
                      //     };
                      //   }
                      props.setAnchorEl(event.currentTarget);
                      props.setAppState({
                        ...props.appState,
                        projectId: props.project.id,
                        folderId: props.item.id,
                        fileId: null,
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingBottom: 0 }}>
            {props.item.children && (
              <ReactSortable
                id={`projectId:${props.project.id}_fId:${props.item.parentId}_id:${props.item.id}`}
                handle={".handle"}
                list={props.item.children}
                setList={(currentList, sortable, store) => {
                  if (
                    store.dragging &&
                    store.dragging.props &&
                    JSON.stringify(store.dragging.props.list) !==
                      JSON.stringify(currentList)
                  ) {
                    setTimeout(() => {
                      setAppState((sourceList) => {
                        try {
                          const tempList = [...sourceList.projects];
                          const _blockIndex = [...props.blockIndex];
                          const lastIndex = _blockIndex.pop();
                          const lastArr = _blockIndex.reduce(
                            (arr, i) => arr[i]["children"],
                            tempList
                          );
                          lastArr[lastIndex]["children"] = currentList;
                          return {
                            ...props.appState,
                            projects: tempList,
                          };
                        } catch (e) {
                          console.log("Error", e);

                          return {
                            ...props.appState,
                            projects: [...sourceList.projects],
                          };
                        }
                      });
                    }, 0);
                  }
                }}
                {...props.sortableOptions}
              >
                {sortFnc(props.item.children, props.sortType).map(
                  (childBlock, index) => {
                    //console.log("childBlock", childBlock);
                    return (
                      <RenderChildrenComp
                        sortType={props.sortType}
                        level={props.level ? props.level + 1 : 1}
                        addOrVisibility={props.addOrVisibility}
                        updateMapFeatureShow={props.updateMapFeatureShow}
                        setInfoLayerTags={props.setInfoLayerTags}
                        setInfoLayerDes={props.setInfoLayerDes}
                        setSection={props.setSection}
                        setCurrentSection={(curr) => {
                          props.setCurrentSection(curr);
                        }}
                        key={childBlock.id}
                        project={props.project}
                        item={childBlock}
                        blockIndex={[...props.blockIndex, index]}
                        openAccordians1={props.openAccordians1}
                        classes={props.classes}
                        handleClickCheck={props.handleClickCheck}
                        device={props.device}
                        projectAccordianOnClick={props.projectAccordianOnClick}
                        appState={props.appState}
                        actionStateProj={props.actionStateProj}
                        setAnchorElProj={props.setAnchorElProj}
                        anchorElProj={props.anchorElProj}
                        deleteProjectFunction={props.deleteProjectFunction}
                        setFilePath={props.setFilePath}
                        setDisplayItems={props.setDisplayItems}
                        displayItems={props.displayItems}
                        setAppState={props.setAppState}
                        iconStyle={props.iconStyle}
                        sortableOptions={props.sortableOptions}
                        allFolders={props.allFolders}
                        openAccordians2={props.openAccordians2}
                        handleClickCheckFolder={props.handleClickCheckFolder}
                        folderAccordianOnClick={props.folderAccordianOnClick}
                        layerPanelSelected={props.layerPanelSelected}
                        updateFolderShow={props.updateFolderShow}
                        // mapRef={props.mapRef}
                        viewer={props.viewer}
                        setMediaAccordian={props.setMediaAccordian}
                        mediaAccordian={props.mediaAccordian}
                        uploadFilesImagery={props.uploadFilesImagery}
                        setUploadFilesImagery={props.setUploadFilesImagery}
                        actionState={props.actionState}
                        setAnchorEl={props.setAnchorEl}
                        fileAccordianOnDoubleClick={
                          props.fileAccordianOnDoubleClick
                        }
                        setLayerPanelSelected={props.setLayerPanelSelected}
                        updateLayerShow={props.updateLayerShow}
                        selectIcon={props.selectIcon}
                        selectName={props.selectName}
                        setDateCreated={(date) => {
                          props.setDateCreated(date);
                        }}
                        setDateInfoPanel={props.setDateInfoPanel}
                        setInfoLayerSelected={props.setInfoLayerSelected}
                        setInfoLayerSelectedId={props.setInfoLayerSelectedId}
                        setInfoLayerSelectedFeatureId={
                          props.setInfoLayerSelectedFeatureId
                        }
                        getFeatureMediaFunction={props.getFeatureMediaFunction}
                        setInfoLayerSelectedMedia={
                          props.setInfoLayerSelectedMedia
                        }
                        setInfoLayerSelectedWMSName={
                          props.setInfoLayerSelectedWMSName
                        }
                        setInfoLayerSelectedName={
                          props.setInfoLayerSelectedName
                        }
                        setInfoLayerProperties={props.setInfoLayerProperties}
                        setRightDrawer={props.setRightDrawer}
                        // infoIconHandler={props.infoIconHandler}
                        handleInfoDrawerOpen={props.handleInfoDrawerOpen}
                      />
                    );
                  }
                )}
              </ReactSortable>
            )}
          </AccordionDetails>
        </Accordion>
      )
    : props.openAccordians2.includes(props.item?.folderId) && (
        <Accordion
          hidden={
            (props.item.name.toLowerCase().includes(".kml") ||
              props.item.name.toLowerCase().includes(".kmz")) &&
            props.item.size > 100 &&
            window.matchMedia("(max-width: 767px)").matches
          }
          className={props.classes?.dragItem}
          key={`layer${props.project.id}_${props.item.id}`}
          id={JSON.stringify({
            action: "layer-order",
            projectId: props.project.id,
            parentId: props.item?.folderId,
            layerId: props.item.id,
            from: props.item.displayOrder,
          })}
          style={{
            margin: 0,
            background:
              Number(props.layerPanelSelected) == Number(props.item.id)
                ? "#901da1"
                : "",
          }}
          onDoubleClick={(event) => {
            if (
              (!props.item.hasOwnProperty("entity") &&
                props.item.layerType == 6) ||
              props.item.layerType == 2
            ) {
              props.addOrVisibility(
                props.item.id,
                props.item.displayStatus,
                props.item,
                true
              );
            } else {
              props.fileAccordianOnDoubleClick(event, props.item);
            }
          }}
          onClick={(event) => {
            event.stopPropagation();
            props.setLayerPanelSelected(props.item.id);
            props.setAppState({
              ...props.appState,
              projectId: props.project.id,
              folderId: props.item?.folderId,
              fileId: props.item.id,
            });
          }}
        >
          <AccordionSummary
            className="kmlItem"
            expandIcon={
              props.item.name.toLowerCase().includes(".kml") ||
              props.item.name.toLowerCase().includes(".kmz") ? (
                <ExpandMoreIcon
                  style={{
                    color:
                      Number(props.layerPanelSelected) == Number(props.item.id)
                        ? "white"
                        : "",
                  }}
                />
              ) : null
            }
            //               style={{color:Number(layerPanelSelected)==Number(item.id)?'white':''}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              onClick={(event) => {
                //event.stopPropagation();
              }}
              style={{
                paddingLeft: "35px",
                paddingRight: "27px",
              }}
              className="projectName"
            >
              {/* <span className={"handle"}>
            <Tooltip title={"Drag"} placement="top">
              <IconButton aria-label="delete" style={{ cursor: "move" }}>
                <DragHandleIcon style={{ color: Number(layerPanelSelected) == Number(item.id) ? 'white' : '' }} />
              </IconButton>
            </Tooltip>
          </span> */}
              <Checkbox
                icon={<VisibilityOffIcon />}
                checkedIcon={<VisibilityIcon />}
                style={{
                  background:
                    Number(props.layerPanelSelected) == Number(props.item.id)
                      ? "white"
                      : "",
                }}
                onClick={(event) => {
                  if (
                    props.item.name.toLowerCase().includes(".kml") ||
                    props.item.name.toLowerCase().includes(".kmz")
                  )
                    event.stopPropagation();
                }}
                hidden={
                  props.item?.status === null ||
                  props.item?.status?.status
                    ?.toLowerCase()
                    .includes("processing") ||
                  props.item?.status?.status
                    ?.toLowerCase()
                    .includes("pending") ||
                  props.item?.status?.status
                    ?.toLowerCase()
                    .includes("undefined") ||
                  props.item?.status?.status?.toLowerCase().includes("failed")
                }
                checked={props.item.displayStatus ? true : false}
                onChange={(event, state) => {
                  event.stopPropagation();

                  // *** need to be update for performance
                  props
                    .updateLayerShow(
                      props.item.displayStatus ? 0 : 1,
                      props.item?.folderId,
                      props.item.id
                    )
                    .then(() => {
                      console.log(
                        "props.item.displayStatus",
                        props.item,
                        state
                      );
                      if (props.item.layerType == 6) {
                        props.setMediaAccordian(!props.mediaAccordian);
                      } else {
                        if (props.item.layerType == 5) {
                          props.item.layerType == 5 &&
                            (props.item.entity.show = props.item.displayStatus
                              ? true
                              : false);

                          props.setMediaAccordian(!props.mediaAccordian);
                        }
                        if (props.item.layerType != 2) {
                          let newfiles = props.uploadFilesImagery.map(
                            (delItem) => {
                              if (delItem.id === props.item.id) {
                                delItem.show = !delItem.show;
                              }
                              return delItem;
                            }
                          );
                          props.setUploadFilesImagery(newfiles);
                        }
                      }
                    });
                  if (props.item.layerType == 6) {
                    props.item.displayStatus = state;
                    props.addOrVisibility(props.item.id, state, props.item);
                  } else if (props.item.layerType == 2) {
                    props.item.displayStatus = state;
                    // props.item["url"] = props.item.folderUrl;
                    // props.item["show"] = state;
                    props.addOrVisibility(props.item.id, state, props.item);
                  } else {
                    props.item.displayStatus = state;
                    props.updateMapFeatureShow(props.item.id, state);
                  }
                }}
              />
              <IconButton>{props.selectIcon(props.item)}</IconButton>
              <Tooltip
                title={props.selectName(props.item)}
                placement="top"
                onDoubleClick={(event) => {
                  if (
                    (!props.item.hasOwnProperty("entity") &&
                      props.item.layerType == 6) ||
                    props.item.layerType == 2
                  ) {
                    props.addOrVisibility(
                      props.item.id,
                      props.item.displayStatus,
                      props.item,
                      true
                    );
                  } else {
                    props.fileAccordianOnDoubleClick(event, props.item);
                  }
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  props.setLayerPanelSelected(props.item.id);
                  props.setAppState({
                    ...props.appState,
                    projectId: props.project.id,
                    folderId: props.item?.folderId,
                    fileId: props.item.id,
                  });
                }}
              >
                <span
                  style={
                    // props.selectName(props.item).length > 10
                    //   ? {
                    //       fontSize: "0.9em",
                    //       width: window.matchMedia("(max-width: 767px)").matches
                    //         ? 90
                    //         : 160,
                    //       display: "inline-block",
                    //       overflowX: "scroll",
                    //       color:
                    //         Number(props.layerPanelSelected) ==
                    //         Number(props.item.id)
                    //           ? "white"
                    //           : "",
                    //       textWrap: "nowrap",
                    //       margin: "0px 0px -6px 0px",
                    //     }
                    //   : {
                    //       margin: "auto 0px",
                    //       fontSize: "0.9em",
                    //       color:
                    //         Number(props.layerPanelSelected) ==
                    //         Number(props.item.id)
                    //           ? "white"
                    //           : "",
                    //     }
                    {
                      fontSize: "0.9em",
                      width: window.matchMedia("(max-width: 767px)").matches
                        ? 90
                        : 160,
                      display: "inline-block",
                      overflow: "hidden",
                      wordBreak: "break-all",
                      verticalAlign: "middle",
                      color:
                        Number(props.layerPanelSelected) ==
                        Number(props.item.id)
                          ? "white"
                          : "",
                      margin: "auto 0px",
                    }
                  }
                >
                  {/* <CapitaliseFirstWord
                name={selectName(item).slice(0, 12).toLowerCase().toString()}
              /> */}
                  {props
                    .selectName(props.item)
                    // .slice(0, 30)
                    .toString()}
                  {/* {props.selectName(props.item).length > 30 && "..."} */}
                </span>
              </Tooltip>
              {props.item.attatchment ? (
                <Tooltip title={"File Attachment"} placement="top">
                  <AttachFileIcon
                    style={{
                      marginLeft: window.matchMedia("(max-width: 767px)")
                        .matches
                        ? "-5px"
                        : "5px",
                      verticalAlign: "middle",
                      color:
                        Number(props.layerPanelSelected) ==
                        Number(props.item.id)
                          ? "white"
                          : "",
                    }}
                  />
                </Tooltip>
              ) : null}
              {/* {props.item.image_taggings && props.item.image_taggings.length > 0 ? ( */}
              {props.item?.entity?.id?.description?.imageTaggings &&
              props.item?.entity?.id?.description?.imageTaggings.length > 0 ? (
                <Tooltip title={"Image Annotated"} placement="top">
                  <RateReview
                    style={{
                      marginLeft: window.matchMedia("(max-width: 767px)")
                        .matches
                        ? "-8px"
                        : "5px",
                      verticalAlign: "middle",
                      // color:
                      //   Number(props.layerPanelSelected) == Number(props.item.id)
                      //     ? "white"
                      //     : "",
                    }}
                  />
                </Tooltip>
              ) : null}
              <IconButton
                aria-label="delete"
                className="layerInfoIcon"
                style={{ float: "right" }}
                onClick={(event) => {
                  getSingleLayer(props.item.id).then((res) => {
                    if (res.data.layer.exifData) {
                      var json_data = res.data.layer.exifData.data[0];
                      var result = [];
                      for (var m in json_data) result.push([m, json_data[m]]);
                      var tableData =
                        '<table style="display: block;width: initial;overflow: auto;"><tr><td>Name</td><td>Value</td></tr>';
                      for (var n = 0; n < result.length; n++) {
                        tableData += "<tr>";
                        tableData += "<td>" + result[n][0] + "</td>";
                        tableData += "<td>" + result[n][1] + "</td></tr>";
                      }
                      tableData += "</table>";
                      props.setInfoLayerProperties(tableData);
                    } else {
                      props.setInfoLayerProperties("");
                    }
                  });
                  let featureId;
                  props.setInfoLayerSelected("Mosaic");
                  featureId = props.item.id;
                  // console.log("featureClicked", props.item);
                  if (featureId) {
                    props.setInfoLayerSelectedId(featureId);
                    props.setInfoLayerSelectedFeatureId(null);
                    props.item.layerType != 7 &&
                      props.getFeatureMediaFunction(featureId, null);
                  } else {
                    props.setInfoLayerSelectedId(null);
                    props.setInfoLayerSelectedFeatureId(null);
                    props.setInfoLayerSelectedMedia([]);
                  }
                  props.setInfoLayerSelectedWMSName("");
                  props.setInfoLayerSelectedName(props.item.name);
                  props.setDateCreated(
                    props.item.createdAt.split("T")[0] +
                      " " +
                      props.item.createdAt.split("T")[1].substring(0, 8)
                  );
                  props.setRightDrawer(350);
                  document.getElementsByClassName(
                    "cesium-viewer-toolbar"
                  )[0].style.right = "263px";
                  props.handleInfoDrawerOpen();
                  props.setMediaAccordian(false);
                  if (
                    (!props.item.hasOwnProperty("entity") &&
                      props.item.layerType == 6) ||
                    props.item.layerType == 2
                  ) {
                    props.addOrVisibility(
                      props.item.id,
                      props.item.displayStatus,
                      props.item,
                      true
                    );
                  } else {
                    props.item.layerType != 7 &&
                      props.fileAccordianOnDoubleClick(null, props.item, false);
                  }

                  // props.item.layerType === 6 &&
                  //   props.infoIconHandler(props.item.entity);
                  //Getting Primitives Info

                  // const viewer = props.mapRef;
                  // console.log(
                  //   "featureIdPrimitives",
                  //   viewer.scene.primitives,
                  //   "viewer",
                  //   viewer
                  // );
                  // const primitivesArray = viewer.scene.primitives._primitives;
                  // const primitives3dTileset = [];
                  // primitivesArray.forEach((obj) => {
                  //   if (obj instanceof Cesium3DTileset) {
                  //     primitives3dTileset.push(obj);
                  //   }
                  // });
                  // console.log(
                  //   "primitives3dTileset",
                  //   primitives3dTileset,
                  //   "featureId",
                  //   featureId
                  // );
                }}
              >
                <InfoIcon />
              </IconButton>
              {/* <IconButton
            aria-label="delete"
            className="layerInfoIcon"
            style={{ color: "red" }}
            onClick={(event) => {
              props.item.layerType === 6 &&
                props.infoIconHandler(props.item.entity);
            }}
          >
            <InfoIcon />
          </IconButton> */}
              {props.project.permission && (
                <IconButton
                  aria-label="delete"
                  style={{
                    position: "absolute",
                    right: window.matchMedia("(max-width: 767px)").matches
                      ? "22%"
                      : "16%",
                  }}
                  onClick={(event) => {
                    try {
                      event.stopPropagation();
                      props.actionState.project = props.project;
                      props.actionState.item = props.item;
                      //   if (event.clientY > 480) {
                      //     props.actionState.anchorPosition = {
                      //       left: event.clientX - 5,
                      //       top: event.clientY - 150,
                      //     };
                      //   } else {
                      //     props.actionState.anchorPosition = {
                      //       left:
                      //         device === "Desktop"
                      //           ? event.clientX - 5
                      //           : event.clientX - 170,
                      //       top: event.clientY - 20,
                      //     };
                      //   }
                      props.setInfoLayerSelectedId(props.item.id);
                      props.setInfoLayerSelectedFeatureId(null);
                      props.setAnchorEl(event.currentTarget);
                      props.setAppState({
                        ...props.appState,
                        projectId: props.project.id,
                        folderId: props.item?.folderId,
                        fileId: props.item.id,
                      });
                      // setDisplayItems({ ...displayItems, openActionDialog: true });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <MoreVertIcon
                    style={{
                      color:
                        Number(props.layerPanelSelected) ==
                        Number(props.item.id)
                          ? "white"
                          : "",
                    }}
                  />
                </IconButton>
              )}
            </Typography>
          </AccordionSummary>
          {props.item.name.toLowerCase().includes(".kml") ||
          props.item.name.toLowerCase().includes(".kmz") ? (
            <AccordionDetails
              style={{
                // backgroundColor:"red",
                marginLeft: "-21px",
                marginRight: "0px",
                paddingBottom: 0,
              }}
            >
              {props.item?.entity?._entityCollection?._entities?._array
                .slice(0)
                .map((childBlock, index) => {
                  //console.log("childBlock", childBlock);
                  return (
                    <RenderChildrenComp
                      sortType={props.sortType}
                      addOrVisibility={props.addOrVisibility}
                      updateMapFeatureShow={props.updateMapFeatureShow}
                      setInfoLayerTags={props.setInfoLayerTags}
                      setInfoLayerDes={props.setInfoLayerDes}
                      setSection={props.setSection}
                      setCurrentSection={(curr) => {
                        props.setCurrentSection(curr);
                      }}
                      key={childBlock._id}
                      project={props.project}
                      item={childBlock}
                      blockIndex={[...props.blockIndex, index]}
                      openAccordians1={props.openAccordians1}
                      classes={props.classes}
                      handleClickCheck={props.handleClickCheck}
                      device={props.device}
                      projectAccordianOnClick={props.projectAccordianOnClick}
                      appState={props.appState}
                      actionStateProj={props.actionStateProj}
                      setAnchorElProj={props.setAnchorElProj}
                      anchorElProj={props.anchorElProj}
                      deleteProjectFunction={props.deleteProjectFunction}
                      setFilePath={props.setFilePath}
                      setDisplayItems={props.setDisplayItems}
                      displayItems={props.displayItems}
                      setAppState={props.setAppState}
                      iconStyle={props.iconStyle}
                      sortableOptions={props.sortableOptions}
                      allFolders={props.allFolders}
                      openAccordians2={props.openAccordians2}
                      handleClickCheckFolder={props.handleClickCheckFolder}
                      folderAccordianOnClick={props.folderAccordianOnClick}
                      layerPanelSelected={props.layerPanelSelected}
                      updateFolderShow={props.updateFolderShow}
                      mapRef={props.mapRef}
                      setMediaAccordian={props.setMediaAccordian}
                      mediaAccordian={props.mediaAccordian}
                      uploadFilesImagery={props.uploadFilesImagery}
                      setUploadFilesImagery={props.setUploadFilesImagery}
                      actionState={props.actionState}
                      setAnchorEl={props.setAnchorEl}
                      fileAccordianOnDoubleClick={
                        props.fileAccordianOnDoubleClick
                      }
                      setLayerPanelSelected={props.setLayerPanelSelected}
                      updateLayerShow={props.updateLayerShow}
                      selectIcon={props.selectIcon}
                      selectName={props.selectName}
                      setDateCreated={(date) => {
                        props.setDateCreated(date);
                      }}
                      setDateInfoPanel={props.setDateInfoPanel}
                      setInfoLayerSelected={props.setInfoLayerSelected}
                      setInfoLayerSelectedId={props.setInfoLayerSelectedId}
                      setInfoLayerSelectedFeatureId={
                        props.setInfoLayerSelectedFeatureId
                      }
                      getFeatureMediaFunction={props.getFeatureMediaFunction}
                      setInfoLayerSelectedMedia={
                        props.setInfoLayerSelectedMedia
                      }
                      setInfoLayerSelectedWMSName={
                        props.setInfoLayerSelectedWMSName
                      }
                      setInfoLayerSelectedName={props.setInfoLayerSelectedName}
                      setInfoLayerProperties={props.setInfoLayerProperties}
                      setRightDrawer={props.setRightDrawer}
                      // infoIconHandler={props.infoIconHandler}
                      handleInfoDrawerOpen={props.handleInfoDrawerOpen}
                    />
                  );
                })}
            </AccordionDetails>
          ) : null}
        </Accordion>
      );
};

export default RenderChildrenComp;
